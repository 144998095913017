@import "~sass-includes/index";

.access-container {
  margin: 16px 8px;
  @media #{$breakpoint-sm-up} {
    @include centered(600);
    max-width: 600px;
    margin: 66px auto 20px;
  }
  .access-container__instructor-banner {
    background-color: $red-light;
    color: $white;
    font-weight: bold;
    margin-bottom: 8px;
    padding: 12px 10px;
    border-radius: 8px;
    text-align: center;
  }
  .access-container__main {
    padding: 12px;
    @media #{$breakpoint-sm-up} {
      padding: 30px;
    }
    h2 {
      margin-bottom: 12px;
    }
    display: flex;
    flex-direction: column;
    background-color: $gray-light;
    border: 1px solid $border-color;
    border-radius: 8px;
    box-shadow: $box-shadow;
    .access-container__access-instructions__display {
      display: flex;
      flex-direction: row;
      .access-container__access-instructions-input {
        width: 100%;
      }
      .access-container__access-instructions-buttons {
        display: flex;
        flex-direction: column;
      }
    }
    .edit-access-instructions {
      margin-left: 10px;
    }
    .access-container__info-text {
      font-size: $font-size-md;
    }
    .access_action-column {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 10px;
      padding-top: 5px;
      button.primary {
        margin: 10px;
        padding-top: 10px;
        font-size: $font-size-md;
      }
      .payment-alert {
        margin-top: 20px;
        color: $coral;
      }
    }

    .access-code-form__wrap {
      width: 100%;
      background-color: $blue-light;
      border-radius: 16px;
      text-align: center;
      .access-code-form__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 8px 4px;
        b {
          font-size: $font-size-xl;
        }
        button {
          font-size: small;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    .access-code-form {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      .access-code-form__inputs {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
      .access-code-form__verify {
        @extend %flex-centered;
      }
      input[type="text"] {
        font-family: $monospace-font;
        width: 23%;
        font-size: 1.5em;
        border: 1px solid;
        border-color: $gray;
        border-radius: 7px;
        padding: 5px;
        text-transform: uppercase;
        &:disabled {
          background-color: $gray-light;
        }
      }
    }
    .access-code-result {
      .access-code-result__error {
        color: $red;
        margin-bottom: 8px;
      }
      .access-code-result__hint {
        font-size: smaller;
        margin-bottom: 16px;
      }
      .access-code-form-button {
        margin-top: 20px;
      }
      &.success-link {
        @extend %standardLink;
        padding-left: 0px;
      }
      &.button {
        height: 30px;
        margin-left: 15px;
      }
    }
    .access-container-support {
      margin-top: 20px;
      line-height: 1.5;
      a {
        color: $blue-dark;
      }
      .access-container-support__subtext {
        margin-top: 0.5em;
        font-size: $font-size-sm;
      }
    }
  }
}
