@import "~sass-includes/index";
.login {
  min-height: 100vh;
  background-color: $gray-dark;
  display: flex;
  justify-content: center;
  align-items: center;
  .toaster-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
.login__box {
  width: 300px;
  padding: 20px;
  background-color: $white;
  border-radius: 5px;
  h2 {
    text-align: center;
    color: $gray-dark;
    font-size: $font-size-h4;
    font-weight: 100;
    margin-block-start: 0;
  }
  input[type="text"],
  input[type="password"] {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid $gray;
    padding: 20px 10px;
  }
  input[type="submit"] {
    @extend %button;
    display: block;
    margin: 20px auto;
    &:disabled {
      background-color: $gray-dark;
    }
  }
}
.login__error {
  color: $red;
  text-align: center;
  font-size: $font-size-xs;
  margin-bottom: 20px;
}

.login__footer-link {
  text-align: center;
}

.login__info {
  color: $green;
  text-align: center;
  font-size: $font-size-xs;
  margin-bottom: 20px;
}

.notification-message {
  font-size: $font-size-xs;
  margin-bottom: 12px;
  padding: 8px;
  background-color: $coral-light;
  border-radius: 5px;
  text-align: center;
}

.userAgreement__box {
  padding: 12px 16px;
  @media #{$breakpoint-md-up} {
    padding: 1em;
    margin: 0 20%;
  }
  .userAgreement__action {
    padding-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .user-agreement__privacy-button {
    @extend %standardLink;
    font-size: large;
    text-align: left;
  }
  .expando__trigger {
    svg {
      margin-right: 4px;
    }
  }
  .agreement__agree-button {
    @extend %button;
    &:disabled {
      background: $gray-light;
      border: 1px solid $border-color;
      color: $button-default-color-disabled;
    }
  }

  .agreement__cancel-button {
    @include secondary-button;
    margin-right: 50px;
  }

  .content {
    font-size: 0.8em;
  }
}

.password-reset-action {
  .password-input-wrap {
    position: relative;
    input[type="text"],
    input[type="password"] {
      margin-bottom: 8px;
      &[data-invalid="true"] {
        border-color: $red;
        &:focus, &:hover, &:active {
          outline-color: $red;
        }
      }
    }
    button.toggle-password-visibility {
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      svg {
        @extend %inline-svg;
        fill: $gray-dark;
      }
      &:hover {
        svg {
          fill: $black;
        }
      }
    }
  }
  ul.password-complexity-list {
    @extend %list-reset;
    margin-bottom: 8px;
    margin-left: 4px;
    li {
      font-size: $font-size-sm;
      svg {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }
  }
}
