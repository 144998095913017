@import "~sass-includes/index";

.add-lo-confirmation {
  display: flex;
  flex-direction: column;
  font-size: smaller;
  padding: 0px 30px 20px;
  .add-lo-confirmation__class-session {
    .add-lo-confirmation__class-session-message {
      padding-top: 15px;
    }
    .add-lo-confirmation__class-session-select {
      margin-top: 10px;
      label {
        @extend %flex-row-centered;
      }
    }
  }
}
