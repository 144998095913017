@import "~sass-includes/index";

.assessment-builder-actionbar {
  background-color: $gray;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  .actionbar-nav__left-side, .actionbar-nav__right-side {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .actionbar-nav__left-side {
    justify-content: flex-start;
  }
  .actionbar-nav__right-side {
    justify-content: flex-end;
  }
}
