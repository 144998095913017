@import "~sass-includes/index";

.study-path-checkpoint-column {
  width: 100%;
  background-color: $gray-light;
  border-radius: 14px;
  overflow: hidden;
  min-height: 480px;
  font-family: $standard-font;
  font-style: normal;
  .column-title {
    padding: 25px 35px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $black;
    font-weight: 600;
    font-size: $font-size-h3;
  }
  .study-path-column-title {
    text-align: left;
    svg: {
      fill: $white;
    }
    .checkpoint-number {
      text-transform: uppercase;
      font-size: $font-size-md;
    }
    .checkpoint-name {
      @extend %hreset;
      line-height: 41px;
    }
  }
  .column-content {
    padding: 15px;
    .h3 {
      margin-block-end: 8px;
      margin-block-start: 8px;
      font-weight: 600;
      font-size: $font-size-xl;
      line-height: 27px;
    }
  }
  .column-content__progress {
    > div {
      box-shadow: inset $box-shadow;
      background-color: $white;
    }
  }
  .empty-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    .empty-column-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: $font-size-h2;
      color: $purple-dark;
      font-weight: 600;
      line-height: 140%;
      svg {
        width: 48px;
        height: 48px;
      }
    }
    .empty-column-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-size: $font-size-sm;
      line-height: 140%;
      letter-spacing: 0.0025em;
      width: 100%;
      .score-description {
        text-transform: uppercase;
        font-size: $font-size-xxs;
      }
    }
  }
}

// this will iterate through the $study-path-color object and set up the styles for each column
.study-path-column {
  margin-bottom: 12px;
  @media #{$breakpoint-sm-up} {
    margin-bottom: 0;
  }
  @each $name, $val in $study-path-color {
    .sp-column-#{$name} {
      background-color: map-get($val, "background");
      .study-path-column-title {
        color: map-get($val, "text");
      }
      .study-path-topic-card__front, .study-path-topic-card__back {
        background-color: $white;
        border-radius: $border-radius;
      }
      .study-path-topic-card {
        border-radius: $border-radius;
        // box-shadow or full card flip, pick one
        .study-path-topic-card__front, .study-path-topic-card__back {
          .study-path-topic-card__expanded-content, .topic-card-flipside {
            .study-path-topic-card__flipper {
              background-color: map-get($val, "lighter");
              svg {
                fill: map-get($val, "dark");
              }
              &:hover {
                background-color: map-get($val, "dark");
                svg {
                  fill: map-get($val, "lighter");
                }
              }
            }
          }
        }

        .study-path-topic-card__back {
          border: 3px solid map-get($val, "dark");
        }
        .flipside-content__resources-title {
          color: map-get($val, "dark");
        }
        &.open {
          .study-path-topic-card__header {
            background: map-get($val, "dark");
            color: $white;
          }
        }
      }
      .column-content, .study-path-tooltip-content {
        svg {
          fill: map-get($val, "dark");
        }
      }
    }
  }
}

.sp-column-test {
  .empty-column-test {
    position: relative;
  }
  .show-explain__trigger {
    margin-top: 8px;
    button {
      text-decoration: underline;
    }
  }
  .practice-test-launch {
    margin-top: 12px;
  }
  .practice-text-explain {
    margin-top: 25px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .test-column-info {
    .all-questions-answered__text {
      margin: 8px 0;
    }
    .all-questions-answered__open-spptat {
      margin-top: 12px;
      font-size: $font-size-sm;
    }
    .practice-text-explain {
      margin: 8px 0;
    }
  }
  .missed-question-example {
    background-color: $white;
    padding-left: 8px;
    align-items: center;
    border: solid 2px $blue;
  }
}
