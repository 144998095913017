@import "~sass-includes/index";

@keyframes topicselect__in {
  0% {opacity: 0; transform: translate3d(0, 30px, 0);}
  100% {opacity: 1; transform: translate3d(0, 0, 0);}
}
.topic-select__items-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topicselect__items {
  position: fixed;
}
.topic-select__active-item {
  //padding: 0px;
  margin: 1px;
  line-height: 20px;
  padding: 1px 5px;
}
.topic-select__active-item:hover {
  background-color: $gray-light;
  border: 1px solid $gray;
  padding: 1px 5px;
  border-radius: 25px;
  box-shadow: 1px 2px 2px $gray;
}
