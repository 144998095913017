@import "~sass-includes/index";

.daily-planner {
  @include centered($max-width);
  .daily-planner__info {
    font-size: $font-size-xl;
    line-height: 27px;
    padding-top: 28px;
    padding-bottom: 24px;
  }
  .daily-planner__content {
    margin-top: 20px;
  }
  .daily-planner__main {
    background-color: $gray;
    border-radius: 10px;
    padding: 22px 36px;
    .daily-planner__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 28px;
    }
    .daily-planner__assessments {
    }
  }
  .daily-planner__sidebar {
    padding-left: 30px;
    width: 300px;
    .sidebar-card {
      &.calendar-card {
        padding: 20px;
      }
      box-shadow: $box-shadow;
      border-radius: $border-radius-sm;
      border: 1px solid rgba(151, 151, 151, 0.3);
      margin-bottom: 24px;
    }
  }
  .daily-card {
    .daily-card__header {
      .daily-planner__column-title {
        font-weight: 600;
        font-size: $font-size-xl;
        line-height: 27px;
      }
    }
  }
}

.daily-planner__topic-group {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  .daily-planner__topic-name {
    font-weight: 700;
    font-size: $font-size-md;
    text-transform: uppercase;
    padding-bottom: 10px;
    color: $black;
  }
  .daily-planner__topic-los {
    .daily-planner__topic-lo {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 12px;
      .daily-planner__topic-lo-number {
        font-weight: 600;
        font-size: $font-size-md;
        display: inline;
        min-width: 60px;
        margin-right: 12px;
      }
      .daily-planner__topic-lo-title {
        line-height: 140%;
        font-size: $font-size-sm;
      }
    }
  }
}

.daily-planner__class-materials {
  .daily-planner__class-materials-item {
    padding: 6px 0;
  }
}

.daily-planner__header-details {
  color: $black;
  .daily-planner__header-week-display {
    font-weight: 700;
    font-size: $font-size-md;
    line-height: 22px;
  }
  .daily-planner__header-date-display {
    @extend %hreset;
    margin-top: 8px;
    font-weight: 600;
    font-size: $font-size-xl;
    line-height: 27px;
  }
}

.alignment-card {
  .alignment-card__header {
    font-weight: 600;
    font-size: $font-size-xl;
    line-height: 27px;
    // color: #3B3C3D; unknown color from figma
    color: $black;
    padding: 20px 20px 15px;
  }
  .alignment-item {
    .alignment-item__header {
      padding: 13px 22px;
      border-top: 1px solid $gray;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $black;
      .alignment-item__header-expand {
        color: $blue;
      }
      .alignment-item__header-pie {
        margin-left: 12px;
        width: 36px;
        height: 36px;
      }
      .alignment-item__header-lo-string {
        margin-left: 14px;
        font-weight: 600;
        font-size: $font-size-md;
        line-height: 22px;
      }
    }
  }
}

$indicator-opacity: 0.1;
$item-height: 36px;

.alignment-item-list__empty {
  padding: 0 20px 20px;
  font-size: $font-size-sm;
}

.alignment-item__content {
  .alignment-item__list {
    .alignment-item__list-item {
      display: flex;
      flex-direction: row;
      min-height: $item-height;
      width: 100%;
      padding-left: 24px;
      margin-bottom: 16px;
      .alignment-item__list-item-indicator {
        @extend %alignment-item__rectangle;
      }
      .alignment-item__list-item-details {
        padding: 0px 16px;
        font-size: $font-size-sm;
        .alignment-item__list-item-assessType {
          color: #000000;
          font-weight: 600;
          font-size: $font-size-sm;
          line-height: 19px;
          margin-bottom: 4px;
        }
        .alignment-item__list-item-info {
          color: #000000;
          font-weight: 400;
          font-size: $font-size-sm;
          line-height: 18px;
          > div {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}
