@import "~sass-includes/index";
.instructions__wrapper {
  border: 1px solid $gray;
  padding: 16px 40px 40px;
  input {
    border: 1px solid $gray;
    border-radius: 4px;
  }
  hr {
    margin: 24px 0;
  }
  .toggle-instruction-type {
    display: flex;
    flex-direction: row;
    label {
      margin-right: 12px;
      input {
        margin-right: 8px;
      }
    }
  }
  .toggle-instruction-type {
    display: flex;
    flex-direction: row;
    label {
      margin-right: 12px;
      input {
        margin-right: 8px;
      }
    }
  }
}

.instructions__select {
  margin-top: 16px;
  label {
    margin: 0 0 0.5rem 0.25rem;
    .instructions__select__url__label {
      margin-bottom: 0;
    }
  }
  .instructions__select__url + p {
    font-weight: lighter;
    font-style: italic;
    margin-top: 0 ;
  }
}

.instructions__select__class-session {
  margin-bottom: 16px;
  .select-class-session__dropdown {
    width: 100%;
  }
}

.instructions__select__title, .instructions__select__url {
  margin-bottom: 16px;
  input {
    width: 100%;
    min-height: 40px;
  }
}

.instructions__select__save {
  height: 40px;
}

.current-instructions-title {
  font-weight: bold;
}

.editable-instruction-row {
  input.editable {
    height: 36px;
    &:read-only {
      background-color: transparent;
      border: 1px solid transparent;
    }
    width: 100%;
  }
  .instructions__update__title {
  }
  .instructions__edit-buttons {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding-top: 4px;
    button {
      svg {
        margin-left: 20px;
      }
      &:disabled {
        opacity: 0.4;
      }
    }
  }
}

table.assigned-instructions {
  table-layout: fixed;
  border-collapse: collapse;
  font-family: $standard-font;
  border: 1px solid;
  border-color: $gray-light;
  width: 100%;
  thead {
    background-color: $gray;
    text-align: left;
    height: 50px;
    .assigned-instructions__header-title {
    }
  }
  tbody {
    tr {
      height: 30px;
      &:nth-child(even) {
        background-color: $gray-light;
      }
      &:hover {
        background-color: $coral-light;
      }
      td {
        vertical-align: middle;
      }
    }
  }
}

.instructions-title {
  font-weight: bold;
}

.instructions__select__url__warning-invalid {
  font-weight: bold;
  color: $red-light;
}

.instructions__select__updated-url__warning-invalid {
  color: $red-light;
  margin: 0;
}

.assigned-instructions__classes {
  width: 25%;
  max-width: 25%;
}
.assigned-instructions__title {
  width: 40%;
  max-width: 40%;
}
.assigned-instructions__url {
  width: 25%;
  max-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.instructions__edit-buttons {
  width: 10%;
  max-width: 10%;
}
