@import "~sass-includes/index";

$card-gutter: 16px;
.course-card {
  position: relative;
  margin: 0 10px;
  border: 1px solid $gray;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.07);
  margin-bottom: 20px;
  // image header
  .course-card__image__parent {
    overflow: hidden;
    .course-card__image {
      width: 100%;
      padding-bottom: 40%;
      background-size: cover;
      background-position: center;
      background-size: cover;
      transition: all .5s;
    }
  }
  .course-card__clickable:hover .course-card__image__parent .course-card__image {
    transform: scale(1.25);
  }

  // content
  .course-card__content {
    position: relative;
    padding: 12px $card-gutter 8px;
    .course-card__title {
      margin-top: 0;
      padding-bottom: 8px;
      margin-block-start: 0;
      margin-block-end: 0;
      font-weight: 600;
      color: $black;
      font-size: 1.17em;
    }
    .course-card__details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $black;
      .course-card__course-number, .course-card__course-start, .course-card__instructor {
        font-size: $font-size-sm;
        > span {
          font-weight: normal;
        }
      }
    }
    .course-card__coinstructor-icon {
      position: absolute;
      top: 20px;
      right: $card-gutter;
      z-index: $zindexStandardTooltip;
      svg {
        height: 22px;
        width: 22px;
        color: $gray-dark;
      }
    }
  }
  .course-card__actions {
    justify-content: space-around;
    height: 2em;
    a.course-card__actions__button {
      @extend %flex-centered;
      text-align: center;
      padding: 2px 0;
      background-color: $white;
      &[data-launchas="student"] {
        background-color: $purple-light;
        color: $purple-dark;
      }
    }
  }
  &[data-isinstructor="false"] {
    // action buttons don't show for students, adjust padding
    padding-bottom: $card-gutter;
  }
  // hover style
  .course-card__image {
    transition: all .5s;
  }
  .course-card__clickable:hover .course-card__image__parent .course-card__image {
    transform: scale(1.25);
  }
  a.course-card__actions__button {
    transition: background-color .5s;
  }
  &:hover .course-card__actions {
    a.course-card__actions__button[data-launchas="instructor"] {
      background-color: $blue-lighter;
    }
  }
}
