@import "~sass-includes/index";

.add-remove-button {
  svg {
    width: 32px;
  }
  &.action-plus {
    color: $purple;
    &:disabled {
      color: $gray;
      cursor: default;
    }
  }
  &.action-minus {
    color: $coral;
    &:disabled {
      color: $gray;
      cursor: default;
    }
  }
}
