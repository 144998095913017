@import "~sass-includes/index";

.lo-library-filter {
  padding: 5px 10px;
  width: 30%;
  padding-right: 10px;
  h3 {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 800;
    font-size: $font-size-md;
    display: flex;
  }
  h4 {
    font-weight: 400;
    font-size: $font-size-sm;
    display: inline;
    margin-left: 6px;
  }
  p {
    color: $purple;
    cursor: pointer;
    font-size: $font-size-sm;
  }
  ul {
    list-style-type: none;
    padding: 0 0 0 10px;
    margin: 0;
    width: 100%;
    transition: all 0.5s ease;
    li {
      display: inline-block;
      width: calc(100% - 20px);
      cursor: pointer;
      font-size: 13px;
      font-weight: 400;
      position: relative;
    }
    svg {
      top: 5px;
    }
  }
  .showList {
    -webkit-animation: fadein 1s;
    animation: fadein 1s;
  }
  .hideList {
    display: none;
  }
}
.lo-library-filter__additional-subjects-divider {
  height: 10px;
  margin-top: 20px;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
}
.lo-library-filter__additional-header {
  margin: 20px 5px 5px;
}
.lo-library-filter__subject-header {
  .lo-library-filter__subject-expand {
    svg {
      transition: all 300ms $ease-out-quint;
    }
    &[data-expanded="true"] {
      svg {
        transform: rotate(90deg);
      }
    }
  }
  .lo-library-filter__subject-name {
    margin-left: 4px;
  }
}
.lo-library-filter__unit__wrap {
  padding-left: 3px;
}
.lo-library-filter__unit {
  display: flex;
  margin: 5px auto;
  margin-left: 8px;
  .lo-library-filter__unit-expand {
    @extend %button-reset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 4px;
    padding-top: 2px;
    &[data-hidden="true"] {
      visibility: hidden;
    }
    svg {
      transition: all 300ms $ease-out-quint;
      width: 14px;
      height: 14px;
    }
    &[data-expanded="true"] {
      svg {
        transform: rotate(90deg);
      }
    }
  }
  .lo-library-filter__unit-name {
    font-size: smaller;
  }
}
.lo-library-filter__unit__topic {
  padding-left: 28px;
  button.checkbox-item__button {
    @extend %button-reset;
    .checkbox-item__icon {
      // topic checkboxes should be smaller than unit checkboxes
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.lo-selector__item-type {
  color: $purple;
  cursor: pointer;
  font-size: $font-size-xxs;
}
@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}
.lo-library-filter__unit .square {
  margin-left: 3px;
}
.lo-library-filter__unit__topic {
  margin-left: 5px;
  &[data-template-topic-custom-unit="true"] {
    .checkbox-item__label {
      font-style: italic;
    }
  }
}
