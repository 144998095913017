@import "~sass-includes/index";
.course-list {
  &:first-child {
    margin-top: 30px;
  }
  &:last-child {
    padding-bottom: 30px;
  }
  margin-top: 10px;
}

// <CourseList>
.course-list__wrapper {
  @include centered($max-width);
  .course-list__section__header {
    display: flex;
    align-items: center;
  }
  .course-list__header {
    @extend %flex-centered;
    justify-content: space-between;
    align-items: center;
    background-color: $gray-light;
    border-radius: $border-radius;
    > div {
      padding: 6px 12px;
    }
    margin-bottom: 12px;
    h2.course-list__header-title {
      font-weight: bold;
      font-size: 1.6em;
    }
    .course-list__header-options {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .toggle-switch {
        display: flex;
        flex-direction: row-reverse;
        margin-right: 8px;
        .toggle-switch-labels > span {
          margin-right: 6px;
          font-size: $font-size-sm;
        }
      }
      .course-list__new-course {
        svg {
          @extend %inline-svg;
          margin-left: 8px;
        }
      }
    }
  }
  .course-list__content {
    margin: 0 16px;
  }
}
// </CourseList>

// <CourseListSection>
.course-list__section {
  .course-list__section__header {
    .course-list__section__header-title {
      @extend %hreset;
      font-size: 1.5em;
      margin-bottom: 10px;
    }
  }
  .course-list__section__courses {}
}
// </CourseListSection>
