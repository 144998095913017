@import "~sass-includes/index";
.course-page{
}
.course-page__instructor {
  .course-page__assessments-wrapper {
    .list-display {
      width: 100%;
    }
  }
}
.assessment-filter__preview-row {
  width: 100%;
  h6 {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
  p {
    margin-left: 30px;
    display: inline-block;
    width: calc(100% - 150px);
  }
  li span {
    color: $green;
  }
}
.assessment-filter__preview-feedback-button {
  @extend %button;
  width: 250px;
  margin-bottom: 20px;
  text-align: center;
  color: $purple;
  border: 1px solid $purple;
  background: transparent;
  display: inline-block;
  margin-right: 25px;
  &:hover {
    color: $white;
  }
  &.is-selected {
    background: $purple;
    color: $white;
  }
}
.assessment-filter__preview-feedback-wrapper {
  &.isHidden {
    visibility: hidden;
  }
}

// Out of Class Controller Styles
.course-page__ooc-wrapper {
  @include centered($max-width);
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.table-wrap {
  width: 75%;
}

.class-select__class-items {
  width: 25%;
}
.class-selector {
  border-radius: 12.5px;
  background-color: $gray;
  padding: 20px;
  width: 25%;
  .class-selector__info {
    text-align: center;
    color: $gray-dark;
    font-size: $font-size-xl;
    font-weight: 600;
    opacity: .5;
  }
}

.class-selector__prev,
.class-selector__next {
  width: 30px;
  opacity: 0.5;
  cursor: pointer;
  color: $gray-dark;
  svg {
    width: 30px;
    height: auto;
  }
}

.class-selector__info {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}
.class-selector__session {
  width: calc(100% - 60px);
  > span {
    font-size: $font-size-sm;
    position: relative;
    top: -3px;
  }
}

.class-selector__topic-item {
  background-color: $white;
  border-radius: 12.5px;
  padding: 25px 15px;
  margin-bottom: 20px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}
