@import "~sass-includes/index";

.next-summative {
  @extend .codon-card;
  overflow: hidden;
  position: relative;
  padding-left: 19px;
  padding-bottom: 25px;

  @media #{$breakpoint-xs-only} {
    margin-top: 16px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 19px;
    height: 100%;
    background-color: $purple-dark;
  }
}

.next-summative__heading {
  color: $white;
  background-color: $purple;
  padding: 14px 20px 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  h2 {
    margin: 0;
    font-size: $font-size-xl;
    font-weight: 600;
    padding-left: 5px;
  }
  svg {
    fill: $white;
  }
}
.next-summative__body {
  padding: 18px;
  margin-top: 15px;
  display: flex;
  h4 {
    margin: 0;
    font-size: $font-size-sm;
    font-weight: 600;
    color: $black;
    margin-bottom: 10px;
  }
  .next-summative__left {
    .next-summative__hero-box {
      display: flex;
      align-items: center;
      font-family: $standard-font;
      @media #{$breakpoint-sm-up} {
        margin: 0 18px 18px 0;
      }
      .next-summative__hero-number {
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-h1;
        line-height: 98px;
        margin-right: 15px;
      }
      .next-summative__hero-text-name {
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-md;
        line-height: 22px;
        margin-bottom: 3px;
      }
      .next-summative__hero-text-date {
        font-size: $font-size-xs;
      }
    }
  }
  .next-summative__right {
    .next-summative__right-panel-row-spacer {
      height: 16px;
    }
  }
}

.next-summative__progress {
  @media #{$breakpoint-sm-up} {
    padding-left: 18px;
    padding-right: 18px;
  }
  color: $black;
  .next-summative__progress-row-text {
    font-size: $font-size-xs;
    font-family: $standard-font;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .next-summative__progress-row-bar {
    font-size: $font-size-xs;
    margin: 8px;
    @media #{$breakpoint-sm-up} {
      margin: 8px 0px 15px;
    }
  }
}

.next-summative__right-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 8px;
  padding: 10px;
}
.next-summative__right-panel-column {
  padding: 20px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.next-summative__right-panel-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.next-summative__right-panel-icon {
  svg {
    width: 48px;
    height: 48px;
  }
}

.next-summative__right-panel-icon, .next-summative__right-panel-title {
  font-size: $font-size-sm;
  @media #{$breakpoint-md-up} {
    padding-bottom: 10px;
  }
}

.next-summative__right-panel-data {
  font-size: $font-size-h3;
  font-family: $standard-font;
  font-style: normal;
  padding-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

.next-summative__right-panel-text {
  font-size: $font-size-xs;
  line-height: 16px;
  font-family: $standard-font;
  margin-bottom: 15px;
  font-weight: 600;
  >a:link {
    @extend %standardLink;
  }
  >a:visited {
    @extend %standardLink;
  }
}
.next-summative__right-panel {
  &[data-panelstate="showInitial"] {
    background: $coral-light;
  }
  &[data-panelstate="beforeHomework"] {
    background: $coral-light;
    svg {
      fill: $gray-dark;
    }
  }
  &[data-panelstate="practiceTestReady"], &[data-panelstate="practiceTestInProgress"] {
    background: $gray;
    color: $purple-dark;
    font-size: $font-size-md;
    svg {
      fill: $purple-dark;
    }
  }
  &[data-panelstate="practiceTestComplete"] {
    background: $pinky-purple;
    color: $purple-dark;
    font-size: $font-size-md;
    svg {
      fill: $purple-dark;
    }
    .hero {
      font-size: $font-size-h2;
    }
    .score-description {
      text-transform: uppercase;
      font-size: $font-size-xxs;
    }
  }
}
.next-summative-recapture-color {
  background-color: $recapture-background;
  svg {
    fill: $recapture-color;
  }
}
.next-summative-muddy-color {
  background-color: $muddy-background;
  svg {
    fill: $muddy-color;
  }
}

.next-summative__studypath-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  @media #{$breakpoint-md-up} {
    padding-top: 24px;
    button {
      width: 112px;
    }
  }
}
.next-summative__studypath-button {
  @extend %button-lighter;
  padding: 8px 10px;
  font-weight: 600;
  font-size: $font-size-sm;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.03em;
  width: 100%;
  >a:link {
    text-decoration: none;
  }
  >a:visited {
    text-decoration: none;
  }
}

.next-summative__studypath-button-text {
  color: $white;
}

.next-summative__footer {
  padding-right: 16px;
  padding-left: 16px;
  font-family: $standard-font;
  font-style: italic;
  font-size: $font-size-sm;
  line-height: 180%;
  text-align: center;
  position: relative;
  min-height: 80px;
  .next-summative__footer-text {
    position: relative;
    padding-left: 20px;
    @media #{$breakpoint-sm-up} {
      padding-left: 50px;
    }
  }
  svg {
    position: absolute;
    left: 16px;
    @media #{$breakpoint-sm-up} {
      left: 50px;
    }
    top: 0;
    stroke: $gray-light;
  }
}
