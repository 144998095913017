@import "~sass-includes/index";

.course-page__lo-selector-controller {
  .exit-block {
    margin-top: 20px;
  }
  .course-page__lo-selector-wrapper {
    @include centered($max-width);
    .course-page__lo-selector {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .lo-sel-card {
      width: 30%;
    }
  }

}
