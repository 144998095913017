@import "~sass-includes/index";

.course-page__header {
  background-color: $gray;
  .course-page__tab-bar-holder {
    @include centered($max-width);
    display: flex;
    justify-content: space-between;
  }
}

.course-page__student {
  .course-page__assessments-wrapper {
    padding-top: 10px;
    @include centered($max-width);
    display: flex;
    align-items: flex-start;
    .list-display {
      width: 100%;
    }

  }
}
.assessment-filter__preview-row {
  width: 100%;
  h6 {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
  p {
    margin-left: 30px;
    display: inline-block;
    width: calc(100% - 150px);
  }
  li span {
    color: $green;
  }
}
.assessment-filter__preview-feedback-button {
  @extend %button;
  width: 250px;
  margin-bottom: 20px;
  text-align: center;
  color: $purple;
  border: 1px solid $purple;
  background: transparent;
  display: inline-block;
  margin-right: 25px;
  &:hover {
    color: $white;
  }
  &.is-selected {
    background: $purple;
    color: $white;
  }
}
.assessment-filter__preview-feedback-wrapper {
  &.isHidden {
    visibility: hidden;
  }
}

// Student Home
.student-course-view {
  @include centered($max-width);
  .student-course-view__grid {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    @media #{$breakpoint-sm-up} {
      margin-top: 30px;
    }
    min-height: 100vh;
  }

  .student-course-view__grid-left {
    width: calc(60% - 15px);
    height: 100%;
  }
  .student-course-view__grid-right {
    width: calc(40% - 15px);
    height: 100%;
  }
}
