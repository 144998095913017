@import "~sass-includes/index";

.Toastify__toast {
  &.Toastify__toast--success {
    color: $black;
    &.toast-info-container {
      background: $blue-light;
    }
    &.toast-success-container {
      background: $aquamarine;
    }
  }

  &.Toastify__toast--error {
    background: $coral;
  }
}
