@import "~sass-includes/index";

.lrn-assess-content {
  @extend .codon-card;
  padding: 36px 54px 8px;
}

.lrn.lrn_widget.lrn_qr.lrn_mcq {
  font-family: $standard-font;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-sm;
  line-height: 140%;
}

.slides-container .progress {
  display: none;
}

.assessment-taker-root {
  width: 100%;
  height: 100%;
  margin-top: 35px;
}

.assessment-taker-root__instructor-banner {
  background-color: $red-light;
  color: $white;
  font-weight: bold;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: center;
  button {
    text-decoration: underline;
  }
}

.assessment-taker-root__wrap {

}

.assessment-taker-root__indicator {
  font-size: $font-size-md;
  vertical-align: super;
}
