@import "~sass-includes/index";

.attempt-data-bar-chart-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 145px;
  padding: 2px 0;
  vertical-align: bottom;
  .attempt-data-bar-container {
    height: 80%;
    width: 80px;
    min-height: 12px;
    margin-bottom: 2px;
    position: relative;
    border: solid 1px;
    border-radius: 5px;
    .attempt-data-bar {
      padding: 0px;
      margin: 0px;
      min-height: 12px;
      background-color: $gray-dark;
    }
    &.no-data-style {
      background: repeating-linear-gradient(
        45deg,
        $gray-light,
        $gray 10px,
      )
    }
  }
  .attempt-data-percentage-display {
    padding-right: 3px;
    padding-left: 3px;
  }
}
