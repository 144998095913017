@import "~sass-includes/index";

.add-unaligned-questions-confirmation {
  display: flex;
  flex-direction: column;
  font-size: smaller;
  padding: 0px 30px 20px;

  .add-unaligned-questions-confirmation__question-pill-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 10px 0px 5px 4px;
    row-gap: 10px;
    .add-unaligned-questions-confirmation__question-pill-container__pill {
      margin: 5px;
      padding: 0px 10px;
      font-size: $font-size-xs;
      border: 1px solid $black;
      background-color: $gray;
      border-radius: $border-radius-lg;
      box-sizing: border-box;
      &[data-uncovered="true"] {
        background-color: $coral;
      }
    }
  }

  .add-unaligned-questions-confirmation__learning-objective-list {
    max-height: 325px;
    overflow: auto;
  }

  .add-unaligned-questions-confirmation__lo {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 5px;
    text-align: left;
    .add-unaligned-questions-confirmation__checkbox {
      width: 40px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .add-unaligned-questions-confirmation__topic {
      font-weight: bold;
    }
    .add-unaligned-questions-confirmation__text {
      margin-right: 15px;
    }
  }
  .add-unaligned-questions-confirmation__item-description {
    padding-left: 5px;
  }
  .add-unaligned-questions-confirmation__item-coverage {
    color: $gray-dark;
  }
  .add-unaligned-questions-confirmation__class-session {
    .add-unaligned-questions-confirmation__class-session-message {
      padding-top: 15px;
    }
    .add-unaligned-questions-confirmation__class-session-select {
      margin-top: 10px;
      label {
        @extend %flex-row-centered;
      }
    }
  }
}
