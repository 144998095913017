@import "~sass-includes/index";

.mfa-token-container {
  .mfa-token-verification {
    .code-input {
      height: 50px !important;
      padding: 0px !important;
      margin: 0 5px;
      font-size: 2rem;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  .btn-action-row {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }

  .verify-error {
    color: $coral;
    text-align: center;
  }

  input.verify-btn:disabled {
    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
      cursor: not-allowed;
    }
    background-color: rgba(0, 0, 0, 0.4);
    cursor: not-allowed;
  }
}

@media #{$breakpoint-xs-only} {
  .mfa-token-verification {
    .code-input {
      width: 2rem !important;
      height: 2rem;
      margin: 0 5px;
      font-size: 1rem;
    }
  }
}
