@import "~sass-includes/index";

$base-padding: 14px;
$max-drawer-height: 340px;
$header-height: 50px;

.topic-lo-picker {
  button {
    @extend %button-reset;
  }
  ul {
    @extend %list-reset;
  }
  .expando {
    @extend %flex-centered;
  }
  .bcp__class-row__card__header {
    height: $header-height;
    .bcp__class-row__card__header-title {
      color: $white;
    }
    .bcp__class-row__card__header-action {
      .topic-action-button {
        color: $white;
        svg {
          fill: $white;
        }
      }
    }
    .bcp__header-action-group {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $purple-light;
      height: $header-height;
      margin-right: 12px;
      .bcp__header-action-group__label {
        font-size: $font-size-sm;
        color: $black;
        padding: 0 8px;
        min-width: 120px;
      }
    }
    .bcp__header-action-button {
      background-color: $purple-dark;
      padding: 6px 12px;
      border-radius: $border-radius-sm;
      font-size: small;
      &:hover {
        background-color: $purple;
      }
      &.topic-action-button__advanced-lo-options {
        &:hover {
          background-color: $purple-light;
          svg {
            fill: $black;
          }
        }
      }
      .bcp__header-action-button__label {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
  .topic-lo-picker__content {
    height: 100%;
    max-height: 100%;
    .topic-lo-picker__topic-list, .topic-lo-picker__topic-los-preview {
      max-height: $max-drawer-height;
      height: $max-drawer-height;
      overflow-y: auto;
    }
    .topic-lo-picker__topic-list {
      margin-right: 12px;
      padding: $base-padding;
      padding-right: 0;
      .topic-lo-picker__subject-name {
        font-size: $font-size-md;
      }
      .topic-lo-picker__subject__unit-header {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        &:disabled {
          opacity: 0.5;
          cursor: default;
        }
        svg {
          width: 1em;
          min-width: 20px;
          height: 1em;
          top: .125em;
          position: relative;
        }
        .topic-lo-picker__subject__unit-name {
          font-size: $font-size-sm;
        }
      }
      .topic-lo-picker__unit-topics__topic-item {
        font-size: $font-size-xs;
      }
    }
    .topic-lo-picker__topic-los-preview {
      padding: $base-padding;
      font-size: $font-size-sm;
      .topic-lo-picker__display-topic {
        display: flex;
        flex-direction: row;
        .topic-lo-picker__display-topic__topic-name {
          font-size: $font-size-lg;
        }
        border-bottom: 1px solid $gray;
        padding-bottom: 4px;
      }
      .topic-lo-picker__display-topic__topic-los {
        margin-top: 8px;
        ul.topic-lo-picker__display-topic__topic-los__lo-list {
          margin-top: 8px;
          li {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .topic-lo-picker__unit {
    &[data-expanded="true"] {
      .expando {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
  li.topic-lo-picker__unit-topics__topic-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    button.topic-item__select-topic {
      @extend %flex-centered;
      svg {
        margin-left: 4px;
        fill: $purple-dark;
      }
    }
    .add-remove-button {
      opacity: 0.2;
      transition: opacity 250ms linear;
    }
    &[data-topic-showing="true"] {
      background-color: $blue-lighter;
      .add-remove-button {
        opacity: 1;
      }
    }
    .topic-lo-picker__unit-topics__topic-item__highlight {
      padding: 0px 8px;
      border-radius: $border-radius-sm;
    }
  }
  .level-1 {
    .level-2 {
      margin-left: 8px;
      .level-3 {
        margin-left: 22px;
      }
    }
  }
  .topic-lo-picker__topic__learning-objective {
    .topic-lo-picker__topic__learning-objective__action {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }
}

.bcp__cancel-x {
  @extend %flex-centered;
  height: 30px;
  width: 30px;
  padding-right: 8px;
}

// <CoursePlannerTopicEditor>
.bcp__topic-editor {
  .bcp__topic-editor__content {
    display: flex;
    flex-direction: column;
    padding: $base-padding $base-padding $base-padding 30px;
    label {
      margin-bottom: 8px;
    }
  }
}
.bcp__topic-editor__content-form {
  display: flex;
  flex-direction: row;
  height: 40px;
  .bcp__topic-editor__content__input {
    width: 100%;
    padding: 8px;
    border-radius: $border-radius;
    border: 1px solid $gray;
    height: 100%;
  }
  button.bcp__topic-editor__content__submit {
    @include primary-button;
    border-radius: $border-radius;
    width: 200px;
    text-align: center;
  }
}

// </CoursePlannerTopicEditor>
