@import "~sass-includes/index";

.special-session__container {
  position: absolute;
  top: 20px;
  z-index: 100;
  .special-session__popup {
    overflow: hidden;
    min-height: 250px;
    width: 441px;
    border-radius: $border-radius-sm;
    box-shadow: $box-shadow;
    background-color: $white;
    position: static;
    z-index: 100;
    margin: 0px 10px 10px 5px;
    .special-session__popup-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      height: 86px;
      font-size: $font-size-h4;
      font-weight: 600;
      border-bottom: 1px solid $border-color;
      padding-top: 30px;
      padding-left: 55px;
      .special-session__popup-close-button {
        margin-right: 15px;
        font-size: $font-size-h4;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .special-session__popup-body {
      padding: 10px 40px 20px 55px;
      display: flex;
      flex-direction: column;
      text-align: left;
      .special-session__popup-body-row {
        padding-top: 25px;
        >input {
          border: 1px solid $gray-dark;
          border-radius: 4px;
          margin-right: 5px;
        }
        &.popup-body-row-note {
          font-style: italic;
        }
      }
    }
    .special-session__popup-footer {
      border-top: 1px solid;
      border-color: $border-color;
      height: 104px;
      padding-top: 30px;
      .special-session__popup-footer-button {
        margin: auto;
        width: 25%;
      }
    }
  }
}
