@import "~sass-includes/index";

.course-activity-check {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  margin: 0px 30px;

  .course-activity-check-search {
    display: flex;
    flex-direction: row;
    margin: 5px;
    align-items: center;
    font-weight: bold;

    .course-activity-check-search__form {
      @extend %flex-row-centered;
      margin: 5px;
      background-color: $gray-light;
      border: 1px solid;
      border-radius: 5px;
      padding: 5px;
      width: 100%;

      textarea {
        font-family: $standard-font;
        width: 600px;
        border: 1px solid;
        border-color: $gray;
        border-radius: 7px;
        margin: 0px 5px;
        padding: 10px 5px;
        line-height: normal;
      }
    }
  }

  .course-activity-check__course {
    margin: 30px 5px 5px;
    .course-activity-check__course-data {
      margin-bottom: 5px;
    }
    table {
      border-collapse: collapse;
      font-family: $standard-font;
      border: 1px solid;
      border-color: $gray;
      box-shadow: 2px 2px $gray-light;
      width: 100%;
    }
    thead {
      text-align: center;
    }
    tbody {
      text-align: left;
    }
    th {
      font-size: $font-size-md;
      font-weight: bold;
      border: 1px solid $gray-dark;
      padding: 15px 5px;
      background-color: $gray;
    }
    tr {
      height: 30px;
    }
    td {
      padding: 15px 5px;
      font-size: $font-size-sm;
      border: 1px solid $gray-dark;
    }
  }
}
