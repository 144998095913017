@import "~sass-includes/index";
.question-preview {
  height: 100%;
  .active-preview-l8yid {
    visibility: hidden;
  }
  .question-preview__header {
    justify-content: space-around;
    font-size: 14px;
    padding: 12px 0 8px 12px;
    border-bottom: 2px solid $gray;
    background-color: $gray-light;
    .question-preview__info {
      display: flex;
      flex-direction: column;
      flex-grow: 5;
      justify-content: space-around;
      align-items: flex-start;
      font-size: 14px;
      min-width: 400px;
      .question-preview__info-title {
      }
      .question-preview__info-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .question-preview__info-item {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding-right: 20px;
          .question-preview__lo-number-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 8px;
          }
          .question-preview__lo-number {
            margin-right: 4px;
            background-color: $gray;
            border-radius: 12px;
            padding: 4px 8px;
            font-size: $font-size-xs;
          }
          .question-preview__info-item-lo-header {
            font-size: font-size-sm;
            font-weight: bold;
            margin-top: 2px;
          }
        }
      }
    }
    .question-preview__actions {
      flex-grow: 3;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: flex-start;
      padding: 8px 8px 8px 20px;
      .question-preview__actions-move {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 5px;
        min-height: 50px;
        .question-preview__actions-move-text {
          padding: 5px 10px;
          border-radius: $border-radius-sm;
          background-color: $white;
        }
      }
      .question-preview__actions-select {
        max-width: 400px;
        min-width: 300px;
        z-index: $zindexPopup;
      }
    }
    .question-preview__actions-kebab-box {
      flex-grow: 1;
      display: flex;
      align-items: baseline;
      position: relative;
      .question-preview__kebab-menu {
        padding-right: 10px;
      }
      .question-preview__custom-icon {
        padding-right: 5px;
        padding-top: 5px;
        width: 25px;
        height: 25px;
        color: $gray-dark;
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .question-preview__container {
    height: calc(100% - 150px);
    overflow-y: auto;
    div.learnosity-item div.lrn-assess-content.content.lrn {
      border: none;
      border-radius: 0;
      box-shadow: none;
    }
  }
}
