@import "~sass-includes/index";

.custom-item-icon__owner-indicator {
  padding-right: 3px;
  vertical-align: middle;
  svg {
    fill: $purple;
  }
  &:hover {
    svg {
      fill: $purple-dark;
    }
  }
}
.custom-item-icon__coinstructor-indicator {
  padding-right: 3px;
  color: $gray-dark;
  vertical-align: middle;
}
