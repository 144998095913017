@import "~react-datepicker/dist/react-datepicker.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import "~sass-includes/index";
@import "~sass-includes/normalize";
@import "~sass-includes/sass-flexbox/main";

html, body, #root, .app, .student-base, .instructor-base, .course-page, .app__base {
  height: 100%;
}
.student-base, .instructor-base {
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  font-family: $standard-font;
  @include font-smooth();
  p, ul, ol {line-height: 130%;}
}
a {
  @extend %standardLink;
}

.course-page__header {
  background-color: $gray;
  .course-page__tab-bar-holder {
    @include centered($max-width);
    display: flex;
    justify-content: space-between;
  }
}

.h1 {
  @extend %h1;
}

.h2 {
  @extend %h2;
}

.h3 {
  @extend %h3;
}

.h4 {
  @extend %h4;
}

.hreset {
  @extend %hreset;
}

.form__field {
  @extend %form-field;
}

button {
  @include button-reset;
  cursor: pointer;
}
#reload-prompt {
  @extend %flex-centered;
  z-index: $zindexStandardTooltip;
  background-color: $gold;
  text-align: center;
  padding: 8px;
  height: 40px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 60%;
  margin: 0 auto;
  button {
    margin-left: 8px;
    font-weight: bold;
    text-decoration: underline;
  }
}
textarea {
  font-family: $monospace-font;
}
// customize scrollbars
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: $gray-light;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: $white;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-dark;
  border: none;
}

.hideForV1 {
  display: none;
}

#app-tooltip-portal {
  position: relative;
  .standard-tooltip {
    z-index: $zindexStandardTooltip;
  }
  .modal-tooltip {
    z-index: $zindexModalTooltip;
  }
}
.form-error {
  color: $red;
}
* > p {
  color: $black;
}

.cke_dialog_container {
  z-index: $zindexModal + 1 !important; // Make sure l8y modal is on top of codon modal, important to override inline z-index
}

.page-centered {
  @include centered($max-width);
}

svg.expando {
  &[data-expanded="true"] {
    transform: rotate(90deg);
  }
}

.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// source: https://www.accessibility-developer-guide.com/examples/hiding-elements/visually/
.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
