@import "~sass-includes/index";

.system-reports {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  margin: 0px 30px;

  .system-reports__menu {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    .system-reports__menu-item {
      padding: 10px 20px 5px;

      &.selected {
        border-bottom: 2px solid $blue;
      }
    }
  }

  table, th, td {
    border: 1px solid $gray-dark;
    border-collapse: collapse;
    font-size: smaller;
    text-align: center;

    .summary-row {
      background-color: $gray-light;
      font-weight: 600;
    }
  }

  .system-reports__params-form {
    display: flex;
    align-items: baseline;
    border: 1px solid;
    background-color: $gray-light;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px;

    .form-field-spacer {
      width: 10px;
    }

    .system-reports__select-wrap {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      .system-reports__select {
        padding-left: 5px;
      }
    }

    .react-datepicker-wrapper {
      width: 200px;

      input[type="text"] {
        min-width: 200px;
        max-width: 200px;
      }
    }
  }

  .system-reports__button {
    height: 25px;
  }

  .system-reports__saqa-count {
    width: 400px;
  }

  .system-reports__report-description {
    font-size: smaller;
    border: 1px solid $blue;
    border-radius: 5px;
    background-color: $blue-lighter;
    margin: 2px 0px 5px;
    padding: 0px 5px 2px;
  }
  .system-reports__all-reports-sortable-column-header {
    color: $green-dark;
    svg {
      margin-left: 2px;
    }
  }
}
