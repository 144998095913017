@import "~sass-includes/index";

.lo-pill-list {
  @extend %lo-number-list;
  .assessment-builder__linked-lo {
    @extend %noselect;
    @extend %lo-number-pill;
    padding: 8px;
    margin-right: 8px;
    margin-bottom: 4px;
    background-color: $gray-light;
    border-radius: $border-radius;
  }
  .assessment-builder__linked-lo:hover {
    @extend %lo-number-emphasized;
    cursor: default;
  }
}
