@import "~sass-includes/index";

.topics-grid {
  width: 100%;
  color: $gray-dark;
}
//out .topic-grid__wrapper table
.topics-grid__wrapper {
  @include centered($max-width);
  border: 2px;
  padding: 2px;
  margin-bottom: 300px;
  table {
    table-layout: fixed;
    border-collapse: collapse;
    border: 2px;
  }
  th {
    border: 1px;
    padding: 2px 8px;
    text-align: left;
    font-size: 13px;
    font-weight: normal;
  }
  tr:hover {
    td {
      background-color: $gray-light;
    }
    td.specialSession {
      background-color: $purple;
    }
    td.noClass {
      background-color: $gray-dark;
    }
  }
  td {
    background-color: $white;
    border: 1px solid $gray;
    padding: 2px 8px;
    text-align: left;
    font-size: 13px;
    font-weight: normal;
    .topics-grid__dayrow-week {
      background-color: $red;
    }
  }
}
.topics-grid__title-bar {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}
.topics-grid__message-container {
  color: $black;
}

td.oddWeek {
  background-color: $gray-light;
}
.topics-grid__titlerow-week {
  width: 5%;
}
.topics-grid__titlerow-class {
  width: 5%;
}
.topics-grid__titlerow-day {
  width: 5%;
}
.topics-grid__titlerow-date {
  width: 5%;
}
.topics-grid__titlerow-unit {
  width: 27%;
}
.topics-grid__titlerow-topic {
  width: 27%;
}
.topics-grid__titlerow-summative {
  width: 10%;
}
.topics-grid__titlerow-postclass {
  width: 8%;
}
.topics-grid__titlerow-preclass {
  width: 5%;
}
th.topics-grid__titlerow-settings, td.topics-grid__dayrow-settings {
  width: 2%;
  text-align: center;
  .topics-grid__dayrow-settings-menu-open {
    svg {
      fill: $purple;
    }
  }
}
th.topics-grid__titlerow-week, td.topics-grid__dayrow-week {
  width: 2%;
  text-align: center;
}
th.topics-grid__titlerow-class, td.topics-grid__dayrow-class {
  width: 3%;
  text-align: center;
}
th.topics-grid__titlerow-day, td.topics-grid__dayrow-day {
  width: 3%;
  text-align: center;
}
th.topics-grid__titlerow-date, td.topics-grid__dayrow-date {
  width: 3%;
  text-align: center;
}
.topics-grid__titlerow-unit {
  width: 24%;
}
.topics-grid__titlerow-topic {
  width: 24%;
}
.topics-grid__titlerow-summative {
  width: 12%;
}
.topics-grid__titlerow-postclass {
  width: 10%;
}
.topics-grid__titlerow-preclass {
  width: 10%;
}

#topics-grid-select-modal {
  position: static;
}
.topic-select {
  position: relative;
}
.topics-grid__dayrow {
  position: relative;
}

.topics-grid__dayrow-tools {
  text-align: center;
}
.topics-grid__dayrow-class {
}
.topics-grid__dayrow-day {
}
.topics-grid__dayrow-date {
}
.topics-grid__dayrow-unit {
}
.topics-grid__dayrow-topic {
}
.topics-grid__dayrow-summative {
}
.topics-grid__dayrow-postclass {
}
.topics-grid__dayrow-preclass {
}
.topics-grid__clear-syllabus {
  border: 2px solid $gray-dark;
  border-radius: 10px;
  padding: 5px;
}
.topics-grid__clear-syllabus:hover {
  background-color: $red;
  color: $white;
}
