@import "~sass-includes/index";
$form-width: 400px;
.course-details__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.course-details__title {
  @extend %hreset;
  font-size: 1.275rem;
  padding: 10px 10px 20px;
  font-weight: normal;
}

%admin-box {
  border: 2px solid;
  border-color: $coral;
  border-radius: 5px;
  background-color: $coral-light;
  padding: 5px;
  margin-top: 5px;
}

.course-details__item {
  display: block;
  &:not(:first-child) {
    padding-top: 8px;
  }
  padding-bottom: 8px;
  label {
    color: $black;
    display: inline-block;
  }
  .input-wrap {
    margin-top: 8px;
    width: 100%;
    input[type="text"] {
      height: 38px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  input[type="text"],
  input[type="email"],
  select {
    height: 30px;
    border: 1px solid $gray;
    border-radius: 4px;
    width: 100%;
    background: $white;
    padding: 0 5px;
    font-size: $font-size-sm;
  }
  .form-error {
    font-size: $font-size-sm;
    text-align: right;
  }
}
.admin-box {
  @extend %admin-box;
  position: relative;
  .admin-box__mask-icon {
    position: absolute;
    top: 4px;
    right: 4px;
    svg {
      fill: $blue;
    }
  }
  .admin-box__contents {
    .form-select, .react-datepicker-wrapper {
      margin: 8px 0 4px;
    }
  }
}
.course-details__admin-message-container {
  @extend %admin-box;
  .admin-message-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
    .admin-message-container-header-mask {
      padding-right: 5px;
    }
    svg {
      fill: $purple;
    }
  }
  .course-details__admin-date {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-bottom: 0px;
  }
}
.course-details__class-days {
  margin-top: 8px;
  .course-details__class-days-label {
    display: block;
    font-weight: 400;
    color: $black;
    font-size: $font-size-sm;
    margin-bottom: 4px;
    sub {
      bottom: 0;
    }
  }
}

.form form, .assessment-builder__form, .course-details {
  display: block;
  > label {
    display: block;
    font-weight: 400;
    color: $black;
    font-size: $font-size-sm;
    sub {
      bottom: 0;
    }
  }
  input[type="text"],
  input[type="email"],
  select {
    height: 30px;
    border: 1px solid $gray;
    border-radius: 4px;
    width: 100%;
    background: $white;
    padding: 0 5px;
    font-size: $font-size-sm;
  }
  .react-datepicker__input-container input {
    height: 44px;
    &.invalid {
      border: 1px solid $red;
    }
  }
  textarea {
    width: 100%;
    min-height: 100px;
    border: 1px solid $gray;
    border-radius: 4px;
  }
}

.course-details {
  @include centered(600);
  width: 600px;
  margin-top: 40px;
  padding: 12px 16px;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .form form {
    display: block;
    padding: 20px;
    box-shadow: 0px 4px 4px $gray;
    border-radius: 10px;
    border-top: 1px solid $gray-light;
    .form__field {
      max-width: 400px;
    }
    .course-details__select-Topic__control {
      max-width: 400px;
    }
    textarea {
      width: 100%;
      min-height: 100px;
      border: 1px solid $gray;
      border-radius: 4px;
    }
  }
  .assessment-presets-tab-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .assessment-presets-tab {
      min-width: 175px;
      background: #FFFFFF;
      height: 67px;
      text-align: center;
      cursor: pointer;
      margin-right: 1px;
      &.selected {
        border-top: 2px solid $gray-light;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      &.not-selected {
        border-bottom: 1px solid $gray-light;
        background-color: $gray-light;
      }
    }
    .assessment-presets-tab-spacer {
      border-bottom: 1px solid $gray-light;
      width: 100px;
    }
  }
  .assessment-presets-form-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px $gray;
    border-radius: 10px;
    &.first-tab {
      border-radius: 0px 10px 10px;
    }
  }
  .assessment-presets-form-row {
    border-bottom: 1px solid $border-color;
    border-top: 0px;
    padding: 20px 20px 10px;
    vertical-align: middle;
    .button-spacer {
      padding-top: 10px;
    }
    &.button-row {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 47px;
      border-bottom: 0px;
      background-color: $gray-light;
      > button {
        width: 100%;
        &:last-child {
          margin-top: 12px;
        }
      }
    }
    .preset-form-row-header {
      font-size: $font-size-lg;
      padding-bottom: 10px;
      .header-details {
        padding-left: 15px;
        font-size: small;
        font-style: italic;
        &[data-assesstype="prep"], &[data-assesstype="practice_test"] {
          padding-left: 0px;
        }
      }
    }
    .preset-form-field-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      .preset-form-field-row-cell {
        width: 350px;
        padding-right: 20px;
      }
      .preset-late-policy-row {
        .preset-late-offset {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 0px;
        }
        input[type="number"] {
          height: 30px;
          border: 1px solid $gray;
          border-radius: 4px;
          padding: 0 5px;
        }
      }
    }
    .preset-form-time-input {
      width: 150px;
    }
    .preset-form-select {
      @extend .form-select;
      padding-left: 15px;
    }
  }
}

.course-details__warning-text {
  font-size: $font-size-md;
  color: $red-light;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: $coral-light;
  padding: 8px;
  border-radius: $border-radius;
}

.form__group {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  input[type="text"] {
    display: block;
    width: calc(80% - 10px) !important;
    margin-right: 10px;
  }
  select {
    display: block;
    width: 20% !important;
  }
  label {
    font-size: $font-size-sm;
    display: flex;
    flex-direction: row;
  }
}

.form__required {
  height: 16px;
  font-size: $font-size-sm;
  color: $red;
  visibility: hidden;
  &.is-visible {
    margin: 5px 0 10px;
    visibility: visible;
  }
}

.course-details__form {
  .select-wrap {
    width: 100%;
  }
  .form-error {
    color: $red;
    font-size: $font-size-sm;
    text-align: right;
  }
  > label {
    display: flex;
    flex-direction: column;
    > input, .select-wrap {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    > div {
      font-size: $font-size-md;
    }
  }
  .course-details__coinstructors {
    svg {
      fill: $gray-dark;
      vertical-align: -2px;
    }
  }

  .day-select {
    label.day-checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 10px 8px 0;
      .day-name {
        margin-left: 10px;
        font-size: $font-size-md;
      }
      > input {
        margin-top: 0;
      }
    }
  }
  .course-details__duration {
    margin-bottom: 10px;
    .form-error {
      margin-top: 4px;
    }
    &[data-has-error="true"] {
      .react-datepicker__input-container input {
        border: 1px solid $red;
      }
    }
  }
  .course-details__duration__date-pickers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          margin-bottom: 0;
        }
      }
    }
    span {
      display: inline-block;
      margin: 0 10px;
    }
  }
  .grade-sync-toggle {
    display: flex;
    flex-direction: row;
    margin: 16px 0;
    svg {
      margin-left: 4px;
      @extend %inline-svg;
    }
  }
  .course-details__action-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    > button {
      &:last-child {
        margin-top: 12px;
      }
    }
  }
}
