
@mixin box-sizing() {
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

@mixin centered($width) {
  width: 100%;
  max-width: $width;
  margin: 0 auto;

  @media(max-width: $width + 60px) {
    width: calc(100% - 20px);
  }
  @media #{$breakpoint-md-up} {
    @media(max-width: $width + 60px) {
      width: calc(100% - 70px);
    }
  }
}
@mixin centered-full-width() {
  width: calc(100% - 4px);
  margin: 0 auto;
  @media #{$breakpoint-md-up} {
    width: calc(100% - 40px);
  }
}
@mixin centered-timeline($width) {
  width: 100%;
  max-width: calc(100% - 100px);
  margin: 0 auto;
  @media(min-width: $width) {
    width: $width - 70;
  }
}

@mixin vert-margins($margin: 80px) {
  margin-top: $margin;
  margin-bottom: $margin;
}

@mixin responsive($_size, $range: false) {
  @if (type-of($_size) == number) {
    @media (max-width: $_size) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $_size)) {
      $_bp: map-get($breakpoints, $_size);
      @if (type-of($_bp) == number) {
        @media (max-width: $_bp) {
          @content;
        }
      } @else if(map-has-key($_bp, min) and map-has-key($_bp, max) and $range == true) {
        @media (min-width: map-get($_bp, min)) and (max-width: map-get($_bp, max)) {
          @content;
        }
      } @else if(map-has-key($_bp, max)) {
        @media (max-width: map-get($_bp, max)) {
          @content;
        }
      } @else if(map-has-key($_bp, min)) {
        @media (min-width: map-get($_bp, min)) {
          @content;
        }
      } @else {
        @error "RESPONSIVE: $breakpoint value for '#{$_size}' is of an unsupported type.";
      }
    } @else {
      @error "RESPONSIVE: '#{$_size}' is not defined within $breakpoints. Currently defined breakpoints: '#{map-keys($breakpoints)}'";
    }
  }
}

@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin nth-range($_start, $_end) {
  @for $_i from $_start to $_end {
    &:nth-child(#{$_i}) {
      @content;
    }
  }
}

@mixin transition-sequence($_timing-gap, $_elCount, $_initial-delay: 0) {
  @for $_i from 1 to $_elCount {
    &:nth-child(#{$_i}) {
      transition-delay: 0ms + $_initial-delay + ($_timing-gap * $_i);
    }
  }
}

@mixin animation-sequence($_timing-gap, $_elCount, $_initial-delay: 0) {
  @for $_i from 1 to $_elCount {
    &:nth-child(#{$_i}) {
      animation-delay: 0ms + $_initial-delay + ($_timing-gap * $_i);
    }
  }
}

@mixin fill-parent() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghost-center($_child: ".ghost-center__child") {
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.28em;
  }
  #{$_child} {
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ellipsis($_nowrap: true) {
  overflow: hidden;
  text-overflow: ellipsis;
  @if ($_nowrap) {
    white-space: nowrap;
  }
}

@mixin font-smooth($_smooth: true) {
  @if $_smooth {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: none;
  }
}

@mixin setup-perspective($depth: $perspective-depth, $origin: center center) {
  perspective: $depth;
  perspective-origin: $origin;
  transform-style: preserve-3d;
}

/* Shared Button Styles from https://www.figma.com/file/ltZyPXrp0GiYwSYXrIYAPT/Codon-Design-System-Library?node-id=871%3A42 */
@mixin primary-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  margin: 0 8px;
  background: $button-default-color;
  border-radius: 20px;
  font-family: $standard-font;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-sm;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.03em;
  color: $white;
  user-select: none;

  border: 2px solid transparent;
  svg {
    fill: $white;
  }
  &:hover {
    transition: all 0.3s ease;
    outline: none;
    background: $button-default-color-hover;
    svg {
      fill: $white;
    }
    color: $white;
  }
  &:focus {
    border: 2px solid $coral-light;
  }
  &:disabled {
    background: $gray-light;
    border: 1px solid $border-color;
    color: $button-default-color-disabled;
    svg {
      fill: $button-default-color-disabled;
    }
  }
  &:active {
    transition: all 0.3s ease;
    svg {
      fill: $button-default-color-hover;
    }
    color: $button-default-color-active;
  }
}
@mixin primary-button-selected {
  @include primary-button;
  border: none;
  outline: none;
  background: $button-default-color-clicked;
  color: $button-default-color-active;
  svg {
    fill: $button-default-color-active;
  }
}

@mixin secondary-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  margin: 0 8px;
  background: $white;
  border: 1px solid $button-default-color;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: $standard-font;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-xxs;
  line-height: 140%;

  svg {
    fill: $button-default-color;
  }
  span {
    margin-top: 8px;
    font-size: $font-size-xxs;
  }
  color: $button-default-color;

  &:hover {
    transition: all 0.3s ease;
    svg {
      fill: $button-default-color-hover;
    }
    color: $button-default-color-hover;
  }
  &:disabled {
    background: $gray-light;
    border: 1px solid $border-color;
    color: $button-default-color-active;
    svg {
      fill: $gray-dark;
    }
  }

  &:active {
    transition: all 0.3s ease;
    svg {
      fill: $button-default-color-hover;
    }
    color: $button-default-color-active;
  }
}
@mixin secondary-button-selected {
  @include secondary-button;
  border: 1px solid rgba(76, 112, 208, 0.5);
  background-color: $purple-light;
  color: $button-default-color-active;
  svg {
    fill: $button-default-color-active;
  }
}

@mixin button-reset {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
}

@mixin reset {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin: 0;
}

@mixin instructor-mini-icon {
  color: $purple;
  font-size: $font-size-sm;
  padding: 3px 7px;
  &:hover {
    color: $button-default-color-hover;
    background-color: $gray-dark;
    border-radius: 7px;
  }
}

@mixin instructor-mini-icon-no-hover {
  color: $purple;
  font-size: $font-size-sm;
  padding: 3px 7px;
}
