@import "~sass-includes/index";

.move-item-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  button {
    color: $white;
    background-color: $blue;
    text-align: center;
    font-weight: 600;
    font-size: $font-size-xs;
    &:disabled {
      opacity: 0.6;
    }
    &:hover {
      background-color: $blue-dark;
    }
    &.cancel-moving {
      background-color: $red;
      &:hover {
        background-color: $red-dark;
      }
    }
    &[data-loading="true"] {
      background: linear-gradient(270deg, $blue-dark, $blue);
      background-size: 400% 400%;
      animation: AnimationName 1.5s ease infinite;
    }
  }
}

@keyframes AnimationName {
  0% {background-position: 0% 50%}
  50% {background-position: 100% 50%}
  100% {background-position: 0% 50%}
}
