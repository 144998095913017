@import "~sass-includes/index";

// TODO: this is bad, this applies to ALL tables in the app, need to make this more specific
// but also make sure it doesn't break the styling because we are unintentionally relying on this style
table {
  td {
    vertical-align: top;
  }
}

.study-path-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // TODO: make this max-width responsive depending on browser width
  max-width: 1260px;
  margin: 0 auto;
}

.row.study-path-header {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 12px;
  @media #{$breakpoint-sm-up} {
    margin-bottom: 25px;
    padding-right: 10px;
  }
  .study-path-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media #{$breakpoint-sm-up} {
      justify-content: flex-start;
    }
  }
  .study-path-header-right {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: $study-box;
    padding: 16px 12px;
    border-radius: 8px;
    .study-path-header-right__select-summative {
      flex-grow: 2;
      padding-left: 5px;
      width: 225px;
      font-size: $font-size-sm;
    }
    svg {
      fill: $purple;
    }
  }
  h1 {
    text-align: left;
    font-weight: 600;
    font-size: $font-size-h2;
    line-height: 57px;
    letter-spacing: -0.02em;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 12px 0 34px;
  }
  .study-path-header__tooltip {
    padding: 12px;
  }
}

.study-path-columns__wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  &.row {
    margin-bottom: 200px;
  }
  .column-title {
    color: $white;
  }

  .study-path-column__hidden {
  }
  .study-path-column {
    padding: 0 8px;

    @media #{$breakpoint-sm-up} {
      margin-bottom: 10px;
    }
  }
  .study-path-column__test {
    .study-path-topic-card.open {
      .study-path-topic-card__header {
        color: $white;
      }
    }
  }
  .study-path-column-title {
    svg: {
      fill: $white;
    }
  }
}

.study-path-tooltip {
  padding: 8px 12px;
}
