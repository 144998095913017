@import "~sass-includes/index";

.countdown-progress-bar {
  display: flex;
  flex-direction: column;
  margin: 20px;
  min-height: 100px;
  .countdown-progress-bar__item {
    padding: 5px;
    margin-bottom: 15px;
    text-align: center;
    font-size: $font-size-h4;
  }
  .countdown-progress-bar__status-display {
    padding: 5px;
    text-align: center;
    font-size: 56px;
  }
  .countdown-progress-bar__progress-box {
    min-height: 100px;
    p {
      font-size: 32px;
      color: $green;
      text-align: center;
    }
    svg {
      width: 32px;
      height: 32px;
      fill: $green;
    }
  }
}
