@import "~sass-includes/index";

.question-selector-question-card-base {
  background-color: $white;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  margin-bottom: 10px;
}

.question-list-sidebar {
  width: 400px;
  background-color: $gray;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  display: flex;
  flex-direction: column;
  .question-list-sidebar__tab-nav {
    padding-left: 10px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    .question-list-sidebar__tab-nav-title {
      font-size: $font-size-sm;
      padding-right: 10px;
    }
    .question-list-sidebar__tab-nav-buttons {
      display: flex;
      justify-content: space-around;
      height: 40px;
      > button {
        margin-right: 0px;
        padding: 10px 25px;
        cursor: pointer;
        border: 0;
        color: $white;
        font-size: $font-size-xs;
        line-height: calc(150%);
        background-color: $gray-dark;
        &.is-active {
          background-color: $white;
          color: $black;
        }
        &:first-child {
          border-right: 1px solid;
          border-radius: 20px 00px 0px 20px;
        }
        &:last-child {
          border-left: 1px solid;
          border-radius: 0px 20px 20px 0px;
        }
      }
    }
  }
  .question-list-sidebar__selected-questions-list {
    min-height: 700px;
  }

  .assessment-info {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $gray-light;
    .assessment-info__assessment-name {
      font-size: $font-size-sm;
    }
    .assessment-info__assessment-details {
      font-size: $font-size-xs;
      margin-top: 4px;
    }
  }
  .question-list-sidebar__add-custom-question {
    @extend .question-selector-question-card-base;
    box-sizing: inherit;
    width: 100%;
    min-height: 50px;
    justify-content: center;
    svg {
      margin-right: 16px;
      fill: $purple;
    }
  }
}

.question-list-sidebar__message-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .question-list-sidebar__message-badge {
    width: 350px;
    padding: 2px;
    margin-top: 7px;
    font-size: smaller;
    color: $red;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
    .edit-bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .edit-mode-label {
        color: $black;
        font-weight: normal;
        padding-left: 10px;
        padding-right: 5px;
      }
    }
  }
}
.question-list-sidebar__tooltip-header {
  font-weight: bold;
}
.question-list-sidebar__tooltip-body {
  margin-top: 12px;
}

.selected-questions-sidebar__chart {
  background-color: $white;
  border-radius: $border-radius-lg;
  padding: 40px 0;
}

.question-list-sidebar__tab-wrap {
  max-height: calc(100vh - 120px);
  padding: 12px;
  overflow-y: auto;
}

.question-list-sidebar__selected-question-draggable {
}

.question-list-sidebar__selected-question-wrapper {
  @extend .question-selector-question-card-base;
  display: flex;
  flex-direction: column;
  .question-list-sidebar__selected-question {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    min-height: 60px;
    .selected-question-list-item__drag-handle {
      width: 15px;
      padding-right: 20px;
      opacity: 0.1;
    }
    .selected-question-list-item__content {
      flex-grow: 10;
      margin-bottom: 2px;
    }
    .selected-question-list-item__points {
      flex-grow: 0;
      text-align: center;
      width: 40px;
      font-size: $font-size-xxs;
      padding-right: 5px;
      margin-right: 10px;
      input {
        width: 47px;
        height: 20px;
        border: 1px solid;
        margin-bottom: 3px;
        border-radius: 5px;
        border-color: $gray;
      }
    }
  }
  .bump-question__container {
    display: flex;
    flex-direction: column;
    background-color: $gray-light;
    border-radius: $border-radius-sm;
    margin: 5px;
    padding: 5px 1px 5px 5px;
    font-size: smaller;
    .bump-question__menu-header {
      display: flex;
      justify-content: space-between;
      padding-left: 2px;
      padding-bottom: 3px;
      .bump-question__popup-close-button {
        padding-right: 10px;
        .bump-question__popup-close-button-icon {
          height: 15px;
        }
      }
    }
    .bump-question__menu-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .bump-question__assessment-dropdown {
        width: 75%;
      }
      .bump-question__assessment-button {
        color: $white;
        padding-right: 5px;
        >button {
          height: 35px;
        }
      }
    }
  }
}
.selected-question-list-item__title {
  display: block;
  .selected-question-list-item__title-text {
    @extend %button-reset;
    font-size: $font-size-xs;
    font-weight: bold;
    color: $purple;
    padding-left: 7px;
    margin-top: 5px;
    cursor: pointer;
  }
}
.selected-question-list-item__info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 1px;
}
.selected-question-list-item__info-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  line-height: 13px;
  > div {
    padding-left: 7px;
    padding-right: 7px;
    border-right: 1px solid $black;
    &:last-child {
      border-right: 0px;
    }
  }
  svg {
    color: $coral;
  }
}
.selected-question-list-item__info-bar-tooltip {
  button {
    color: $purple;
    &:hover {
      background-color: $gray-light;
    }
  }
  > strong {
    color: $coral;
  }
}
.selected-question-list-item__icon-bar {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  .selected-question-list-item__icon {
    @include instructor-mini-icon;
    color: $purple;
    font-size: $font-size-sm;
    padding: 3px 7px;
    cursor: pointer;
    &:hover {
      color: $button-default-color-hover;
      background-color: $gray-dark;
      border-radius: 7px;
    }
    &[data-isselected=true] {
      border-radius: 7px;
      background-color: $gray-light;
    }
    &.custom-item-icon__coinstructor-indicator {
      align-self: center;
      svg {
        fill: $gray-dark;
      }
    }
  }
}
.selected-question-list-item__icon-disabled {
  @include instructor-mini-icon-no-hover;
  padding: 3px 7px;
  color: $gray;
  cursor: default;
  .selected-question-list-item__icon-lock {
    color: $purple;
  }

}
