@import "~sass-includes/index";

$block-padding: 20px 30px;

.confirmation-modal__overlay {
  @extend %modal__overlay;
  // this needs to be over 10000 because react-select has a 9999 z-index
  z-index: $zindexPopup;
  background-color: $confirmation-overlay-background;

  .confirmation-modal__wrapper {
    @extend %modal__wrapper;
  }

  .confirmation-modal {
    z-index: #{$zindexPopup + 20};
    background: $white;
    position: fixed;
    max-height: 90%;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 450px;
    border-radius: 10px;
    box-shadow: $box-shadow;
    border: 1px solid $gray;
    display: flex;
    flex-direction: column;

    .confirmation-modal__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: 1px solid $gray;
      min-height: 60px;
      padding-left: 30px;
      padding-top: 15px;
      padding-bottom: 15px;

      &.header-alert {
        background-color: $white;
        font-size: $font-size-h4;
      }

      &.header-warn {
        color: $white;
        background-color: $coral;
      }

      .confirmation-modal__header-icon {
        height: 40px;
        width: 40px;
      }

      .confirmation-modal__header--cancel {
        margin-right: 15px;
        font-size: $font-size-h4;
        width: 24px;
        height: 24px;
        cursor: pointer;

        &:hover {
          color: $button-default-color-hover;
        }
      }
    }

    .confirmation-modal__content {
      width: 100%;
      min-height: 100px;
      overflow-y: auto;

      .confirmation-modal__title {
        font-size: $font-size-h4;
        line-height: 32px;
        border-color: $gray-dark;
        padding-bottom: 5px;
        padding: $block-padding;
      }

      .confirmation-modal__body {
        padding: $block-padding;
      }
    }

    .confirmation-modal__button-bar {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-top: 1px solid $gray;
      padding: 20px 30px 10px;

      .confirmation-button {
        @include primary-button;
        background-color: $coral;
        &[data-tri-level-color="true"] { // for align group question conf prompt and maybe others where we need three button colors
          background-color: $blue;
          border-color: $blue;
          &:hover {
            background-color: $purple;
            border-color: $purple;
          }
        }
      }

      .secondary-button {
        @include secondary-button;
        margin-top: 20px;
        color: $coral;
        font-size: $font-size-sm;
        border: 1px solid $coral;
      }

      .confirmation-text-button {
        color: $coral;
        font-size: $font-size-sm;
      }

      button {
        padding-top: 20px;
      }
    }
  }
}

.confirmation-modal__content-wrapper {
  position: relative;
  max-height: 100%;
}
