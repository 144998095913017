@import "~sass-includes/index";

.assessment-list-container {
  @include centered-full-width();
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  table {
    border-collapse: collapse;
    font-family: $standard-font;
    border: 1px solid;
    border-color: $gray;
    box-shadow: 2px 2px $gray-light;
    width: 100%;
    td:first-child, th:first-child {
      padding-left: 20px;

    }
    td:last-child, th:last-child {
      padding-right: 10px;
    }
    tr:nth-child(odd) {
      td {
        background-color: $gray-light;
      }
    }
  }
  thead {
    text-align: left;
    width: 90%;
    .centered {
      text-align: center;
    }
  }
  th {
    font-size: 13px;
    font-weight: bold;
    border-bottom: 1px solid $gray-dark;
    padding: 35px 4px 8px;
    vertical-align: middle;
  }
  tr {
    height: 30px;
    &.first-row {
      height: 15px;
    }
    &.spacer-row {
      height: 20px;
      border-top: 1px solid $gray;
      background-color: $white;
    }
  }
  td {
    text-align: left;
    font-size: 13px;
    font-weight: normal;
    vertical-align: middle;
  }
}

.assessment-list-container__heading {
  @extend %page-heading;
  width: 100%;
  text-align: left;
  margin: 5px 0 20px 10px;
  font-weight: bold;
  font-size: $font-size-xl;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .assessment-list-container__heading-edit-mode {
    font-weight: normal;
    font-size: $font-size-md;
    display: flex;
    flex-direction: row;
  }
}

.assessment-list__table-row {
  &[data-published="no"] {
    color: $gray-dark;
    a {
      color: $gray-dark;
      font-size: 13px;
    }
    .assessment-list__status-cell {
      font-style: italic;
    }
  }
  .assessment-list__name {
    min-width: 290px;
  }
  .assessment-list__number-cell {
    min-width: 50px;
    max-width: 90px;
    text-align: center;
  }
  .assessment-list__status-cell {
    min-width: 60px;
    max-width: 150px;
    text-align: center;
    > div {
      min-width: 60px;
      max-width: 150px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .toggle-switch {
      margin-left: 8px;
    }
  }
  $smaller-font: 12px;
  .assessment-list__date {
    min-width: 120px;
    max-width: 140px;
    font-size: $smaller-font;
    .react-datepicker-wrapper {
      width: 100%;
      height: 100%;
      .react-datepicker__input-container {
        height: 100%;
        font-size: $smaller-font;
        input {
        }
      }
    }
    .assessment-date__datetime-picker {
      width: 100%;
      height: 100%;
      padding: 2px 4px;
      border: 1px solid $gray;
      border-radius: 4px;
      &.invalid {
        border-color: $red;
      }
    }
  }
  .assessment-list__late-date {
    min-width: 150px;
    &[data-wide="true"] {
      min-width: 200px;
      width: 200px;
    }
    .assessment-list__late-date__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .confirmation-modal {
    color: $true-black;
    font-size: $font-size-md;
    .grade-sync-confirmation-prompt__checkbox {
      padding-top: 20px;
      align-items: center;
    }
  }
}
