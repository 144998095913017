@import "~sass-includes/index";

$lo-topic-width: 120px;
$lo-blooms-width: 50px;
$lo-title-width: 320px;
$lo-row-padding: 24px;
.lo-library {
  width: calc(70% - 12px);
  margin-right: 12px;
  .lo-library__wrapper {
    border: 1px solid $gray;
    border-radius: $border-radius-lg;
    padding: 3px;
    position: relative;
    z-index: 1;
    min-height: 100vh;
    .lo-library__search-bar {
      padding: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .lo-library__inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px;
    }
    .lo-library__table-header, .lo-library__table-body {
      .lo-library-item__gutter-btn {
        position: absolute;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .lo-library-item__table-row__title {
        border-left: $lo-row-padding solid transparent;
        width: $lo-title-width;
      }
      .lo-library-item__table-row__kebab-button {
        width: 32px;
        &.hide-kebab {
          visibility: hidden;
        }
      }
      .lo-library-item__table-row__topic {
        width: $lo-topic-width;
      }
      .lo-library-item__table-row__blooms {
        width: $lo-blooms-width;
        text-align: center;
      }
      .lo-library-item__table-row__action, .lo-library-item__table-row__spacer {
        width: $lo-row-padding;
      }
    }
    .lo-library__table-body {
      .lo-library__table-body__results-header {
        @extend %flex-row-centered;
        background-color: $gray-light;
        &[data-has-search-term="true"] {
          background-color: transparentize($green-light, 0.7);
          &[data-notfound="true"] {
            background-color: transparentize($coral-light, 0.4);
          }
        }
        margin: 0 8px 0 12px;
        padding: 5px 12px;
        border-radius: $border-radius-sm;
        font-size: small;
        button {
          color: $blue-dark;
          text-decoration: underline;
        }
      }
    }
  }
}

.lo-selector__tab {
  color: $gray-dark;
  border: 1px solid $gray;
  border-bottom: 0;
  padding: 15px 20px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: $font-size-sm;
  background-color: $white;
  &:not(:first-child) {border-left: 0}
  &.is-selected {
    color: $purple;
    padding: 25px 20px;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    margin-bottom: -1px;
  }
}

.lo-selector__button {
  @extend %button;
  margin-left: auto;
  margin-bottom: 10px;
  &:disabled {
    background-color: $gray;
  }
}

.lo-library__lo-list {
  color: $gray-dark;
  width: 68%;
}
