@import "~sass-includes/index";
.accommodations {
  padding: 10px 40px 40px;
  border: 1px solid $gray;
  border-radius: $border-radius $border-radius 0px 0px;
  &.top-box {
    border-radius: $border-radius;
    margin-bottom: 10px;
  }
  input {
    border: 1px solid $gray;
    border-radius: 4px;
  }
  hr {
    margin: 35px 0;
  }
}

.accommodations__select {
  display: flex;
  align-items: flex-end;
  margin-top: 2rem;
  label {
    margin: 0 0 0.75rem 0.25rem;
  }
}

.accommodations__select__selections {
  display: flex;
  align-items: baseline;
}

.accommodations__select__student {
  margin-right: 2rem;
  .accommodations__select__student-count {
    @extend %noselect;
    @extend %lo-number-pill;
    padding: 4px;
    margin-left: 30px;
    margin-right: 8px;
    background-color: $gray-light;
    border-radius: $border-radius;
  }
  #select-student {
    min-width: 300px;
    max-width: 700px;
  }
}

.accommodations__select__due-date {
  .react-datepicker__input-container input {
    width: 240px;
    height: 40px;
  }
}

.accommodations__select__save {
  height: 40px;
  margin-left: 30px;
}

.current-accommodations__list {
  table.assigned-accommodations {
    border-collapse: collapse;
    font-family: $standard-font;
    border: 1px solid;
    border-color: $gray-light;
    width: 100%;
    thead {
      background-color: $gray;
      text-align: left;
      height: 50px;
    }
    tbody {
      tr {
        height: 30px;
        &:nth-child(even) {
          background-color: $gray-light;
        }
        &:hover {
          background-color: $coral-light;
        }
        td {
          vertical-align: middle;
          svg {
            margin-left: 20px;
          }
        }
      }
    }
  }

}
