@import "~sass-includes/index";

$disabled-on-color: $green-light;
$disabled-off-color: $gray;
$on-color: $green;
$off-color: $gray-dark;

.toggle-switch {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  input {
    appearance: none;
    min-width: 48px;
    min-height: 22px;
    margin: 0;

    position: relative;
    border-radius: 32px;
    background-color: $off-color;
    border: 1px solid $off-color;

    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s, border-color 0.3s;

    &:checked {
      background-color: $on-color;
      border-color: $on-color;

      &::before {
        left: unset;
        transform: translateX(calc(100% + 12px));
      }

      &::after {
        content: attr(data-on);
        left: 0.4em;
        right: unset;
      }
    }
    &:disabled {
      background-color: $disabled-off-color;
      border: 1px solid $disabled-off-color;
      &:checked {
        background-color: $disabled-on-color;
        border-color: $disabled-on-color;
      }
    }

    &::before {
      content: "";
      position: absolute;
      height: 16px;
      width: 16px;
      top: 2px;
      left: 2px;
      bottom: 2px;
      background-color: $white;
      transition: all 0.3s;
      border-radius: 50%;
      cursor: pointer;
    }

    &::after {
      content: attr(data-off);
      color: $white;
      display: block;
      position: absolute;
      font-size: $font-size-xxs;
      line-height: 1;
      top: 0.5em;
      right: 0.4em;
      bottom: 0.5em;
    }

    &:focus {
      outline: 2px solid $blue-light;
      outline-offset: 2px;
    }
  }

  &-labels {
    display: flex;
    flex-direction: column;
    gap: 4px;

    span {
      cursor: pointer;
      line-height: 1.4;
      font-size: $font-size-md;
      user-select: none;
    }

    p {
      font-size: $font-size-xs;
      letter-spacing: 0.4px;
      line-height: 1.3;
      margin: 0;
      color: $gray;
    }
  }
}
