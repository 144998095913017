@import "~sass-includes/index";

.completion-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  padding: 2px 5px 0;
  margin-top: 2px;
  margin-bottom: 0px;
  vertical-align: bottom;
  .completion-icon {
    margin-right: 10px;
    svg {
      fill: $black;
    }
  }
  .completion-message {
    color: $black;
    font-size: $font-size-xs;
    margin-top: 4px;
    margin-left: 15px;
  }
  .completion-percentage {
    margin-left: 7px;
  }
  .completion-bar-container {
    width: 100%;
    height: 10px;
    min-width: 50px;
    margin-bottom: 5px;
    margin-left: 7px;
    background-color: $gray-light;
    border-radius: 5px;
    .completion-bar {
      height: 10px;
      background-color: $gray-dark;
      border-radius: 5px;
    }
  }
}
