@import "~sass-includes/index";

.study-path-instructor-guide__body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .study-path-instructor-guide__content-toggle {
    display: flex;
    flex-direction: row;
    width: 300px;
    border-radius: 25px;
    overflow: hidden;
    border: 1px solid $gray;
    margin-bottom: 15px;
    > button {
      flex: 1;
      padding: 10px 0;
      text-align: center;
      cursor: pointer;
      background-color: $gray-light;
      color: $gray-dark;
      &[data-selected="true"] {
        cursor: default;
        background-color: $blue;
        color: $white;
      }
      &:first-child {
        border-radius: 25px 0 0 25px;
      }

      &:last-child {
        border-radius: 0 25px 25px 0;
      }
    }


  }
  .study-path-instructor-guide__content {
    div[data-hidden="true"] {
      display: none;
    }
  }
  // the styles below came from storylane, slightly modified here for sizing
  .study-path-instructor-guide__embed {
    position: relative;
    padding-bottom: 100px;
    transform: scale(1);
    .study-path-instructor-guide__storylane-demo {
      width: 1050px;
      height: 700px;
      border: 1px solid $gray-light;
      border-radius: $border-radius;
      box-sizing: border-box;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
