/**
 * Shared Layout Styling
 *
 * Put styles here which span across multiple similar pages
 * e.g. AssessmentsCovered and GradeSync pages
 */

/**
 * Diptych style
 * Side-by-side lists of available and selected elements
 */

%diptych-layout {
  display: flex;
  flex-direction: column;
}
%diptych-layout__header {
  font-size: large;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px;
  color: $white;
  background-color: $gray-dark;
  border-radius: 8px;
  margin-bottom: 20px;
  .better-tooltip__wrap {
    margin-left: 12px;
  }
}
%diptych-layout__row {
  width: 100%;
  margin-bottom: 20px;

}
%diptych-layout__column {

}
%diptych-layout__column-right {
  min-height: 444px;
  background-color: $gray;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
%diptych-layout__action-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

%diptych-layout__section {
  padding: 16px;
  &:not(:first-child) {
    margin-top: 30px;
  }
}

%diptych-layout__section-header {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
  border-bottom: 2px solid $gray-dark;
}
%diptych-layout__list {
  @extend %ul-reset;
  max-height: 400px;
  overflow-y: auto;
  > li {
    &:nth-child(even) {
      background-color: $gray-light;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 8px 12px;
      font-size: $font-size-xs;
      width: 100%;
    }
  }
}
%diptych-layout__list-name {
  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
  input {
    margin-right: 12px;
    transform: scale(1.3);
    display: block;
    margin-top: 0;
  }
}
