@import "~sass-includes/index";

.assessments-covered {
  display: flex;
  flex-direction: column;
  .assessments-covered__infobar {
    @extend %diptych-layout__header;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .assessments-covered-list__empty {
    background-color: $gray-light;
    height: 100%;
    padding: 8px 12px;
  }

  .assessments-covered__row {
    @extend %diptych-layout__row;
    .assessments-covered__column {
      .assessments-covered__section {
        @extend %diptych-layout__section;
        &.selected-los {
          background-color: $gray;
        }
      }
      .assessments-covered__section-header {
        @extend %diptych-layout__section-header;
      }
      &.assessments-covered__column-right {
        @extend %diptych-layout__column-right;
      }
      .lo-number-list {
        @extend %lo-number-list;
        .lo-number-pill {
          @extend %lo-number-pill;
        }
      }
    }
  }
  ul.assessments-covered-list {
    @extend %diptych-layout__list;
  }
}

.assessments-covered-list__name {
  @extend %diptych-layout__list-name;
}
.assess-type-pill {
  @extend %assess-type-pill;
}
