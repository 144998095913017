@import "~sass-includes/index";

$list-item-padding: 2px 0;

.assessment-filter {
  position: relative;
  background-color: $white;
  h3 {
    color: $black;
    display: inline-block;
  }
}

.assessment-filter__header-bar {
  height: 7px;
  background-color: $purple;
}
.assessment-filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
  padding: 15px 10px;
  .assessment-filter__header-title {
    font-size: $font-size-xl;
  }
}

.assessment-filter__header-reset {
  font-size: 15px;
  color: $purple;
  cursor: pointer;
}

.assessment-filter__wrapper {
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
  border-bottom: 1px solid $gray;
  .assessment-filter__drawer-column {
    overflow-y: auto;
    max-height: 320px;
  }

  .assessment-filter__header-reset {
    margin-left: 12px;
    margin-top: 8px;
  }
  ol {
    margin: 0 auto 0 0px;
    padding-inline-start: 24px;
  }
  ul {
    @extend %list-reset;
    margin-left: 28px;
    margin-top: 0px;
    margin-bottom: 0px;
    li {
      text-decoration: none;
    }
  }
  li {
    display: flex;
    margin-right: 15px;
    list-style-type: none;
    cursor: pointer;
    margin: 8px 0px;
    padding: $list-item-padding;
  }
  .assessment-filter__block {
    @extend %noselect;
    .filter-title {
      margin: 5px auto 3px 10px;
      cursor: pointer;
      font-size: $font-size-sm;
      font-weight: normal;
      line-height: 16px;
      &.assessment-filter__drawer-column-title {
        font-weight: bold;
        padding-top: 8px;
        display: flex;
        flex-direction: row;
        .better-tooltip__wrap {
          margin-left: 8px;
        }
      }
      &.assessment-filter__drawer-divider {
        height: 10px;
        width: 70%;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;
      }
      svg {
        &.isActive {
          transform: rotate(90deg);
        }
        margin-right: 4px;
      }
    }

    &.lo-filter {
      li {
        align-items: flex-start;
      }
    }
    &:last-of-type p {
      color: $purple;
      cursor: pointer;
    }
  }
}

.assessment-filter__block-unit, .assessment-filter__block-clo {
  @extend .assessment-filter__block;
  overflow-x: auto;
}

.assessment-filter__block-clo span {
  color: $purple;
}

.assessment-filter__sub-topic {
  padding-left: 10px;
  cursor: pointer;
  font-size: $font-size-xs;
  &[data-no-children="true"] {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
  .assessment-filter__sub-topic-expand {
    transition: all 300ms $ease-out-quint;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $list-item-padding;
    .sub-topic__chevron-container {
      &[data-no-children="true"] {
        .sub-topic__chevron {
          visibility: hidden;
        }
      }
    }
    .sub-topic__chevron, .sub-topic__checkbox {
      margin-right: 5px;
    }
    &[data-expanded="true"] {
      .sub-topic__chevron {
        transform: rotate(90deg);
      }
    }
    .sub-topic__checkbox {
      svg {
        height: 18px;
        width: 18px;
      }
    }
    .sub-topic__name {
      display: inline-block;
      font-size: $font-size-xs;
      text-align: left;
    }
  }
  ul.sub-topic__list {
    margin-left: 30px;
    li {
      margin: 0;
      line-height: 20px;
      .checkbox-item__button {
        svg {
          margin-top: 3px;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}

.assessment-filter__status {
  display: flex;
  justify-content: center;
  p {
    margin: 5px 0 0 10px;
    font-size: $font-size-xs;
  }
}

.assessment-filter__status-switch {
  margin-left: 20px;
  margin-top: 1px;
}

.assessment-filter__los {
  overflow-x: auto;
  font-size: $font-size-xs;
  span {
    font-weight: bold;
  }
  svg {
    width: 15px;
    height: 15px;
  }
}

.assessment-filter__items {
  @extend .assessment-filter__los;
  li {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.assessment-filter__toggles {
  padding-left: 8px;
  font-size: $font-size-sm;
}
