@import "~sass-includes/index";
.lo-sel-card {
  border-radius: 12.5px;
  background-color: $gray;
  padding: 20px;
  .lo-sel-card__info {
    text-align: center;
    color: $black;
    font-size: $font-size-xl;
    font-weight: 600;
    opacity: .5;
  }
}

.lo-sel-card-drop-placeholder {
  min-height: 10px;
}
.selected-lo-card .lo-sel-card__objective {
  background-color: $white;
}

.lo-sel-card__board {
  margin-bottom: 20px;
}

.lo-sel-card__topic-item {
  background-color: $white;
  border-radius: 12.5px;
  padding: 10px 15px 8px;
  margin-bottom: 20px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.lo-sel-card__topic-header {
  border-bottom: 1px solid $gray;
  margin-bottom: 15px;
}
.lo-sel-card__topic-details {
  padding-bottom: 5px;
  position: relative;
  color: $black;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .lo-sel-card__topic-details-name {
    @extend %button-reset;
    width: 100%;
  }
  input {
    color: $black;
    font-size: $font-size-sm;
    font-weight: 400;
    cursor: pointer;
    border: none;
    margin: 2px;
    width: calc(100% - 30px);
    line-height: 1.5;
  }
  span {
    color: $black;
    font-size: $font-size-sm;
    font-weight: 400;
    cursor: pointer;
    border: none;
    margin: 2px;
    width: calc(100% - 30px);
    display: inline-block;
    padding: 0;
    line-height: 1.5;
  }
  svg.lo-sel-card__topic-handle {
    cursor: grab;
    position: relative;
    &:first-of-type {
      top: 5px;
      left: -3px;
      color: $gray;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.lo-sel-card__edit-hint {
  position: absolute;
  right: 30px;
  display: none;
  .lo-sel-card__topic-item:hover & {
    display: block;
  }
}
.lo-sel-card__objective {
  border: 1px solid $gray;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  svg { margin-top: 5px; }
  p {
    margin: 0;
    &:first-child {
      color: $purple;
      font-weight: bold;
      font-size: $font-size-sm;
      margin-bottom: 5px;
      margin-left: 35px;
    }
  }
}
.lo-sel-card__objective-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  p {
    width: 100%;
    margin: 0;
    max-width: calc(100% - 35px);
    color: $gray-dark;
    font-size: $font-size-sm;
  }
  svg {
    position: relative;
    top: -23px;
    left: -5px;
  }
}
.lo-sel-card__edit-title {
  background-color: rgba(0, 0, 0, .5);
  svg {
    color: $white;
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin: 0 10px;
  }
  width: 100%;
  height: 40px;
  color: $white;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 7px 0;
  transition: all 300ms $ease-out-quint;
  transform: translate3d(0, 100%, 0);

}
.lo-sel-card__objective:hover > .lo-sel-card__edit-title {
  -webkit-transform: translate3d(0, -40px, 0);
  transform: translate3d(0, -40px, 0);
}
.lo-sel-card__add-lo-button-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .lo-sel-card__add-custom-lo {
    font-size: $font-size-sm;
    font-weight: 400;
    color: $black;
    border-radius: 12.5px;
    border: 1px solid $gray;
    background-color: $white;
    padding: 2.5px 15px;
    cursor: pointer;
  }
}
.lo-sel-card__add-topic, .lo-sel-card__add-custom-lo {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  border-radius: 12.5px;
  background-color: $white;
  padding: 4px 15px;
  color: $purple;
  cursor: pointer;
  width: 100%;
  margin-bottom: 8px;
  p {
    display: inline-block;
    margin-left: 25px;
  }
  input {
    width: 100%;
    margin: 4px 0;
  }
  svg {
    position: relative;
    margin-right: 8px;
  }
  > div {
    color: $black;
    font-size: $font-size-sm;
    font-weight: 400;
    cursor: pointer;
    border: none;
    margin: 2px;
    width: calc(100% - 30px);
    display: inline-block;
    line-height: 2;
  }
}
.lo-sel-card__icon {
  color: $purple;
  width: 20px;
  height: 20px;
  position: relative;
  svg {
    opacity: 1.0;
  }
}
.lo-sel-card__icon-bars {
  width: 20px;
  height: 20px;
  color: $gray;
}
.lo-sel-drag-wrapper {
  background-color: $white;
  height: 200px;
  margin-top: 20px;
  padding: 10px;

}
.lo-sel__drag {
  background-color: $gray;
  margin-top: 10px;
  height: 40px;
}
button.lo-selector__icon-btn {
  cursor: pointer;
  padding-right: 3px;
  vertical-align: middle;
  svg {
    cursor: pointer;
  }
}
.lo-selector__icon-btn {
  transition: all 300ms $ease-out-quint;
  &:disabled {
    opacity: 0.6;
  }
}
.lo-sel-card__handle {
  cursor: grab;
}
.lo-sel-card__info {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}
.lo-sel-card__prev,
.lo-sel-card__next {
  width: 30px;
  opacity: 0.5;
  cursor: pointer;
  color: $black;
  svg {
    width: 30px;
    height: auto;
  }
}
.lo-sel-card__session {
  width: calc(100% - 60px);
  color: $black;
  > span {
    font-size: $font-size-sm;
    position: relative;
    top: -3px;
  }
}

.kebab-menu.lo-selector-card__kebab-menu {
  padding: 0 8px;
  figure {
    width: 5px;
    height: 5px;
  }
  ul.dropdown {
    button {
      font-size: $font-size-sm;
    }
  }
}
