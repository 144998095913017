@import "~sass-includes/index";

.bcp__cancel-moving-topic {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  .bcp__cancel-moving-topic__container {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .bcp__cancel-moving-topic__message {
      text-align: center;
      margin-bottom: 8px;
    }
  }
  button {
    pointer-events: auto;
    background-color: $red;
    &:hover {
      background-color: $red-dark;
    }
  }
}
