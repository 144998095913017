@import "~sass-includes/index";

.study-path__summary-bar.row {
  margin-top: 16px;

  @media #{$breakpoint-sm-up} {
    margin-top: 66px;
  }
  margin-bottom: 20px;
  max-width: $max-width;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: $gray-light;
  border: 1px solid $border-color;
  border-radius: 8px;
  box-shadow: $box-shadow;
  &[data-instructor-view="true"] {
    width: 1050px;
    padding: 5px;
  }
}
.study-path__summary-bar-no-summative {
  height: 200px;
  font-size: 22px;
  padding-left: 150px;
  padding-right: 150px;
  color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.study-path__summary-bar-instructor-view {
  @extend .study-path__summary-bar-no-summative;
  height: 100px;
  width: 100%;
  padding: 5px;
  font-size: $font-size-lg;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;
}

.study-path__summary-bar > .study-path__summary-bar-element {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 16px;
  @media #{$breakpoint-md-up} {
    justify-content: center;
    margin: 20px 0;
    &.hero-border {
      border-right: 1px solid $gray-dark;
    }
  }
  .hero-number {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 56px;
    @media #{$breakpoint-md-up} {
      font-size: $font-size-h1;
      line-height: 98px;
    }
    color: $summary-bar-stats;
    align-items: center;
    .sidekick {
      font-size: 32px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 8px;
      text-align: center;
    }
  }
  .element-description {
    margin: 5px;
    &.prior-summative {
      font-weight: 600;
      color: $purple;
    }
    .element-description__date {
      margin-top: 4px;
      font-size: $font-size-xs;
    }
  }
}
.study-path__summary-bar-icon-lo {
  padding-top: 15px;
}

.study-path__summary-bar-icon {
  min-width: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.study-path__summary-bar-icon-test {
  padding-top: 15px;
  width: 64px;
  svg {
    fill: get("study-path.test.dark");
  }
}
.study-path__summary-bar-days-until {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.study-path__summary-bar-icon-recapture {
  padding-top: 15px;
  svg {
    fill: $recapture-color;
  }
}
.study-path__summary-bar-metric {
  display: inline-block;
}
.study-path__summary-bar-tooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  .study-path__summary-bar-tooltip-icon {
    margin-right: 8px;
    svg {
      fill: $blue;
    }
  }
}
