@import "~sass-includes/index";

.edit-question-confirmation {
  display: flex;
  flex-direction: column;
  padding: 0px 30px 20px;
  .edit-question-confirmation__explanatory-text {
    font-size: small;
    font-style: italic;
    padding-left: 10px;
    padding-top: 10px;
  }
}
