@import "~sass-includes/index";

.question-builder__tab.lo-tab {
  max-height: 100%;
  height: 100%;
  .lo-panels {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 70px solid transparent;
    .top-panel, .bottom-panel {
      padding: 8px 30px;
    }
    .top-panel {
      max-height: 25%;
      height: 25%;
      border-bottom: 3px solid $gray;
      padding-bottom: 16px;
      box-shadow: $box-shadow;
      .selected-los {
        overflow-y: auto;
        max-height: 100%;
      }
      .question-builder__needs-los {
        background-color: $coral-light;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
      }
    }
    .bottom-panel {
      max-height: 70%;
      height: 70%;
      position: relative;
      overflow-y: auto;
      .available-los {
        max-height: 100%;
      }
    }
  }
}

td.question-lo-picker__lonumber {
  width: 65px;
}
.question-lo-picker__action {
  width: 30px;
}
.question-lo-picker__action-button {
  svg {
    fill: $purple;
  }
}
.question-lo-picker__remove-button {
  svg {
    fill: $gray;
  }
}
.question-builder__lo-header {
  display: block;
  margin-top: 8px;
  margin-bottom: 12px;
}
