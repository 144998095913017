@import "~sass-includes/index";

.lo-library__table-header {
  display: flex;
  background-color: $gray;
  color: $gray-dark;
  margin-bottom: 10px;
  border-radius: $border-radius;
  > div {
    margin: 10px 0;
    font-weight: 800;
    opacity: 0.7;
    font-size: $font-size-sm;
  }
}

.lo-library-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $gray;
  padding-top: 10px;
  padding-bottom: 20px;

  .lo-library-item__table-row {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    font-size: $font-size-sm;
    margin-right: 10px;
  }

  .lo-library-item__details {
    margin-top: 0;
    padding: 16px 20px;
    font-size: $font-size-sm;
    color: $gray-dark;
    ul {
      font-size: 13px;
      line-height: 150%;
    }
  }
}
.lo-library-item__class-numbers {
  display: block;
}

.lo-library-item__class-number-pill {
  display: inline-block;
  background-color: $gray-dark;
  border-radius: 10px;
  padding: 2px 7px;
  color: $white;
  font-size: $font-size-xs;
  margin-right: 5px;
  margin-top: 10px;
}

.lo-library-item__left-icon {
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
  transition: all 300ms $ease-out-quint;
  &[data-expanded="true"] {
    transform: rotate(90deg);
  }
}

.lo-library-item__action {
  transition: all 300ms $ease-out-quint;
  color: $purple;
  margin-left: auto;
  &[data-selected="true"] {
    color: $coral;
  }
  &[data-selected="false"] {
    color: $purple;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.lo-library__topic-pill {
  border-radius: 10px;
  display: inline-block;
  padding: 2px 5px;
  margin: 1px;
  font-size: $font-size-xxs;
  &[data-lo-topic-pill-type="course"] {
    background-color: $blue-lighter;
  }
  &[data-lo-topic-pill-type="library"] {
    background-color: $gray-light;
  }
}
