@import "~sass-includes/index";

$cubic-out: cubic-bezier(.32, 2.04, .85, .54);
$cubic-in: cubic-bezier(.72, 1.2, .71, .72);
$easing: cubic-bezier(1.000, 0.000, 0.000, 1.000);

$transition-time: 0.2s;

.kebab-menu__wrap {
  position: relative;
  button.kebab-menu__trigger {
    cursor: pointer;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 16px;
    top: 8px;
    figure {
      width: 6px;
      height: 6px;
      border-radius: 5px;
      background: $gray-dark;
      margin: 3px 0;
    }
    .middle {
      transition: all $transition-time $cubic-in;
      transform: scale(1);
      position: relative;
      box-shadow: 0 0.1px 0.1px 0 rgba(0, 0, 0, 0.16), 0 0.1px 0.3px 0 rgba(0, 0, 0, 0.12);
    }
    .cross {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      margin-top: -1px;
      color: $white;
      transition: all 0.2s $cubic-in;
      font-size: 22px;
      user-select: none;
    }
    &.active {
      .middle {
        transform: scale(4.5);
        transition: all $transition-time $cubic-out;
        box-shadow: 0 0.1px 0.1px 0 rgba(0, 0, 0, 0.16), 0 0.1px 0.3px 0 rgba(0, 0, 0, 0.12);
        border-radius: 0 5px 5px 0;
      }
      .cross {
        transform: translate(-50%, -50%) scale(1);
        transition: all 0.15s $cubic-out;
      }
    }
  }

  ul.kebab-menu__dropdown {
    @extend %list-reset;
    position: absolute;
    right: 32px;
    z-index: $zindexPopup;
    transition: all $transition-time $easing;
    transform: scale(0);
    transform-origin: 100% 0;

    &.kebab-dropdown-bottom {
      top: 4px;
    }
    &.kebab-dropdown-top {
      bottom: -2px;
      transform-origin: 100% 100%;
    }
    box-shadow: $menu-box-shadow;
    background-color: $white;
    li {
      display: block;
      width: 100%;
      text-align: left;
      a, button {
        font-weight: 600;
        text-align: left;
        width: 100%;
        padding: 1em 18px;
        display: inline-block;
        white-space: pre;
        box-sizing: border-box;
        color: $black;
        font-size: $font-size-sm;
        &:hover {
          background: $gray;
        }
        > svg {
          margin-right: 8px;
        }
        &:disabled {
          color: $gray-dark;
          cursor: default;
        }
        &[data-disabled="true"] {
          color: $gray-dark;
        }
      }
    }
    &:hover {
      ul {
        transform: scale(1);
      }
    }
  }
  &[data-expanded="true"] {
    ul.kebab-menu__dropdown {
      transform: scale(1);
      transition: all $transition-time $easing;
    }
  }
}

.kebab-menu-small {
  button.kebab-menu__trigger {
    padding: 0 6px;
    position: relative;
    top: 0;
    figure {
      width: 4px;
      height: 4px;
      margin: 2px;
    }
  }
}

.split-button {
  display: flex;
  flex-direction: row;
  .split-button__primary-action {
    border-radius: 12px 0 0 12px;
    margin-right: 2px;

    &[data-verb="add"] {
      background-color: $purple;
      &:disabled {
        background-color: $gray;
        cursor: default;
      }
    }
    &[data-verb="remove"] {
      background-color: $coral;
      &:disabled {
        background-color: $gray;
        cursor: default;
      }
    }
  }
  .split-button__dropdown-trigger {
    border-radius: 0 12px 12px 0;
  }
  .split-button__primary-action, .split-button__dropdown-trigger {
    @extend %flex-centered;
    background-color: $purple;
    padding: 4px 8px;
    svg {
      fill: $white;
    }
    &:hover {
      background-color: $purple-dark;
    }
  }
  .kebab-menu__wrap ul.kebab-menu__dropdown {
    transition: none;
  }
  ul.kebab-menu__dropdown.kebab-dropdown-bottom {
    top: 0;
  }
}
