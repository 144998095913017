@import "~sass-includes/index";
.move-topic-to-class-session {
  font-size: $font-size-sm;
  background-color: $gray-light;
  border-radius: $border-radius-sm;
  margin: 5px;
  padding: 5px 1px 5px 5px;
  .move-topic-to-class-session__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .move-topic-to-class-session__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .move-topic-to-class-session__select {
      width: 75%;
      margin-right: 5px;
    }
  }
}
