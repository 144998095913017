@import "~sass-includes/index";

.api-error {
  background-color: $coral-light;
  padding: 8px 12px;
  border-radius: $border-radius;
  margin-right: 12px;
  h1 {
    font-weight: bold;
    font-size: $font-size-lg;
    color: $red;
  }
  p {
    font-size: $font-size-sm;
    color: $red;
    margin: 5px;
  }
}
