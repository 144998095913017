@import "~sass-includes/index";

.class-label-icon {
  svg {
    color: $class-day-label-color;
  }
  &[data-classtype="special"] {
    svg.non-class-day {
      color: $non-class-day-color-active;
      fill: $non-class-day-color-active;
    }
  }
  &[data-classtype="normal"] {
    svg.bullhorn {
      color: $class-day-label-color;
      fill: $class-day-label-color;
      transform: rotate(-24deg);
    }
  }
}
