@import "~sass-includes/index";

.confirm-remove-topic-los-questions__container {
  display: flex;
  flex-direction: column;
  min-height: 100px;
}
.confirm-remove-topic-los-questions__message {
  margin-bottom: 15px;
  padding: 15px 30px;
}
.confirm-remove-topic-los-questions__button-message {
  margin-top: 10px;
  font-style: italic;
}
.text-button.confirmation-text-button {
  text-align: center;
}
