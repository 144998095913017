@import "~sass-includes/index";

.toaster-header {
  &[data-flavor="info"] {
    background-color: $gold-light;
  }
  &[data-flavor="update"] {
    background-color: $blue-light;
    a, button {
      color: $purple-dark;
      text-decoration: underline;
      font-weight: bold;
    }
  }
  &[data-flavor="warn"] {
    // TODO: Figure out what color warn should be when we need a warning toaster
    background-color: $gold-light;
  }
  &[data-flavor="error"] {
    background-color: $coral;
  }
  &[data-flavor="downtime"] {
    background-color: $coral-light;
  }
  .toaster-header__content {
    @include centered($max-width);
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &:not(:first-child) {
    border-top: 1px solid $gray-dark;
  }
}
