@import "~sass-includes/index";

.study-path-assessment-taker__question-nav {
  border: 1px solid $border-color;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  @media #{$breakpoint-sm-up} {
    margin-left: 24px;
  }
  .study-path-assessment-taker__question-nav-header {
    background: $purple-dark;
    box-shadow: inset 0px -1px 0px $gray-dark;
    padding: 8px 12px;
    color: $white;
    font-weight: 600;
    font-size: $font-size-xs;
    line-height: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
      @include button-reset;
      padding: 4px;
      &:disabled {
        color: $gray-dark;
      }
    }
  }
  ul.todo-questions, ul.past-questions {
    list-style-type: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;

    li {
      width: 100%;
      button {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        text-align: left;
        box-shadow: inset 0px -1px 0px $gray-dark;
        margin: 0;
        .nav-item__content {
          display: flex;
          flex-direction: column;
          padding: 12px 12px 14px 8px;
          font-weight: 600;
          min-width: 130px;
          .question-title {
            font-size: $font-size-sm;
            line-height: 140%;
            color: $black;
          }
          .question-LO {
            font-size: $font-size-xxs;
            line-height: 140%;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: $gray-dark;
          }
        }
        &.active {
          background-color: $blue-lighter;
        }
      }
    }
    .nav-item {
      cursor: pointer;
      .nav-item__status {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px;
          text-align: center;
          width: 32px;
        }
      }
    }
  }
}

.study-path-practice-test-assessment-taker__question-nav {
  @extend .study-path-assessment-taker__question-nav;
  .todo {
    background-color: $coral;
  }
}
