@import "~sass-includes/index";

.study-path-assessment-taker__wrap {
  @media #{$breakpoint-sm-up} {
    padding: 40px;
  }
  .assessment-taker__header {
    background: $purple-lighter;
  }
  .study-path-assessment-taker__assessment-wrap {
    .study-path-assessment-taker__container {
      max-width: 100vw;
    }
  }
}

.study-path-assessment-taker__content {
  max-width: 100%;
  max-height: 100%;
}

.overlay__study-path {
  .learnosity-item__trigger-validate-button, .overlay__muddy-clear {
    margin-top: 18px;
  }
}
.study-path-assessment-taker__sidebar-buttons {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}

.assessment-taker__header {
  border-radius: 10px;
  padding: 18px 32px;
  @media #{$breakpoint-sm-up} {
    margin-bottom: 24px;
  }
  .assessment-taker__header__topic-title {
    display: flex;
    align-items: flex-end;
    align-items: center;
    h1 {
      @include reset;
      font-weight: 600;
      font-size: $font-size-h4;
      line-height: 33px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
    }
  }
  .assessment-taker__header__lo-item {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-sm;
    line-height: 140%;
    letter-spacing: 0.0025em;
    margin-top: 10px;
    .assessment-taker__header__lo-number {
      font-weight: 600;
      font-size: $font-size-md;
      line-height: 140%;
      text-transform: uppercase;
      color: $black;
      align-items: baseline;
    }
  }
}

.spat-nav-menu {
  margin: 12px 0;
  @media #{$breakpoint-sm-up} {
    margin: 0;
  }
}
