// this is the standard defintion for form fields and used throughout the app
// it is extended by the app in App.scss
%form-field {
  margin-bottom: 24px;
  > input {
    border-radius: 4px;
    padding: 10px;
    border: 1px solid $gray;

  }
  label {
    display: block;
    .assessType, .display-select-input, input, textarea {
      margin-top: 8px;
    }
    .label-text {
      margin-bottom: 8px;
    }
    .form-select {
      font-weight: normal;
      margin-top: 8px;
    }
  }
}

$border-color: $gray;

%button {
  padding: 10px 30px;
  font-size: $font-size-sm;
  cursor: pointer;
  background: $purple-dark;
  border: 0;
  color: $white;
  border-radius: 50px;
  text-decoration: none;
  user-select: none;
  &:hover {
    background: $purple;
  }
}
%button-lighter {
  padding: 10px 30px;
  font-size: $font-size-sm;
  cursor: pointer;
  background: $purple;
  border: 0;
  color: $white;
  border-radius: 50px;
  text-decoration: none;
  &:hover {
    background: $purple-dark;
  }
}

%lo-number-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  padding-bottom: 8px;
  padding-top: 8px;
}

%lo-number {
  font-weight: bold;
  font-size: $font-size-sm;
  color: $purple;
}

%lo-number-pill {
  font-size: $font-size-xs;
  color: $purple;
  white-space: nowrap;
}

%lo-number-emphasized {
  font-size: $font-size-xs;
  color: $purple-dark;
}

%action-plus-button {
  margin-left: auto;
  color: $purple;
  cursor: pointer;
  svg {width: 24px;height: 24px;}
  &:hover {
    color: $purple-dark;
  }
}

%action-plus-button-inactive {
  margin-left: auto;
  color: $gray;
  cursor: pointer;
  svg {width: 24px;height: 24px;}
  &:hover {
    color: $gray-dark;
  }
}
%action-tools-button {
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    color: $purple-dark;
  }
}
%page-heading {
  font-size: $font-size-h4;
  text-align: center;
}

%list-reset {
  text-indent: 0;
  list-style-type: none;
  margin: 0;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

%button-reset {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  text-align: left;
}

%ul-reset {
  list-style: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

%status-badge-sm {
  color: $white;
  padding: 2px 5px;
  border-radius: 0;
  font-size: small;
  font-weight: bold;
}

a {
  @extend %standardLink;
}

.codon-card {
  background-color: $white;
  border: 1px solid $border-color;
  box-shadow: $box-shadow;
  border-radius: 10px;
  &.padded {
    padding: 59px 54px;
  }
}

%hreset {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

%h {
  @extend %hreset;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;
}

%h1 {
  @extend %h;
  font-size: $font-size-h1;
  line-height: 98px;
  margin: 0;
}

%h2 {
  @extend %h;
  font-size: $font-size-h2;
  line-height: 57px;
  letter-spacing: -0.02em;
  margin: 0;
}

%h3 {
  @extend %h;
  font-size: $font-size-h3;
  line-height: 41px;
}

%h4 {
  @extend %h;
  font-size: $font-size-h4;
  line-height: 33px;
}

// for clickable text-based elements, don't select on click
%noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

%flex-row-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
}
%flex-centered {
  @extend %flex-row-centered;
  justify-content: center;
}

%modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindexModal;
  width: 100vw;
  height: 100vh;
}

%modal__wrapper {
  z-index: #{$zindexModal + 10};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
%cardstyle {
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}
%assess-type-pill {
  border-radius: 8px;
  font-size: $font-size-xxs;
  margin-right: 8px;
  padding: 0 4px;
  &.readiness {
    background-color: get("assess-type.preclass");
  }
  &.preclass {
    background-color: get("assess-type.preclass");
  }
  &.homework {
    background-color: get("assess-type.homework");
  }
}
$item-height: 36px;
$indicator-opacity: 0.1;
%alignment-item__rectangle {
  width: 16px;
  min-width: 16px;
  min-height: $item-height;
  &[data-assesstype="preclass"] {
    border: 2px solid get("assess-type.preclass");
    background-color: rgba(get("assess-type.preclass"), $indicator-opacity);
    &[data-filled="true"] {
      background-color: get("assess-type.preclass");
    }
  }
  &[data-assesstype="homework"] {
    border: 2px solid get("assess-type.homework");
    background-color: rgba(get("assess-type.homework"), $indicator-opacity);
    &[data-filled="true"] {
      background-color: get("assess-type.homework");
    }
  }
  &[data-assesstype="summative"] {
    border: 2px solid get("assess-type.summative");
    background-color: rgba(get("assess-type.summative"), $indicator-opacity);
    &[data-filled="true"] {
      background-color: get("assess-type.summative");
    }
  }
}

%standardLink {
  font-family: $standard-font;
  font-size: inherit;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.04px;
  text-align: inherit;
  text-decoration: none;
  color: $purple;
  cursor: pointer;
  // this enables disabling of react-router-dom <Link> components
  &[data-disabled="true"] {
    pointer-events: none;
    font-weight: normal;
    color: $black;
    cursor: default;
  }
}

%today-indicate {
  border: 1px solid $purple;
  border-radius: $border-radius;
}

%inline-svg {
  position: relative;
  width: 1em;
  height: 1em;
  top: .125em;
}

%hover-icon-button {
  svg {
    color: $blue-light;
  }
  &:hover {
    svg {
      color: $blue;
    }
  }
}
