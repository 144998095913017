@import "~sass-includes/index";

.exit-block {
  padding-top: 8px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  span {
    margin: 0 10px;
  }
  h2, h6 {
    margin: 0;
    color: $black;
  }
  h2 {
    font-size: $font-size-xl;
  }
  h6, button {
    font-size: 15px;
    color: $black;
  }
  .exit-block__button {
    font-weight: 700;
    svg {
      margin-left: 4px;
    }
  }
  svg {
    height: 100%;
    width: 15px;
    margin-bottom: -3px;
  }
}
