@import "~sass-includes/index";

td.topics-grid__dayrow-noclass {
  padding: 0px;
  margin: 0px;
  border: 0px;
  text-align: center;
  flex-direction: row;
  width: 100%;
}

.topics-grid__dayrow-noclass {
  padding-left: 7px;
  background-color: $gray-dark;
  text-align: center;
  color: $white;
  font-weight: 600;
  border-radius: 3px;
}
