@import "~sass-includes/index";
.modal__question-builder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 5%;
  .question-builder {
    background-color: $white;
    max-height: 100%;
  }
}
.question-builder {
  position: relative;
  height: 100%;
  min-height: 300px;
  max-height: 100%;
  border-radius: $border-radius;
  border: 1px solid $gray;
  overflow: hidden;
  .learnosity-box {
    overflow-y: auto;
    width: 100%;
    .lrn-author {
      width: 100%;
    }
  }
  form {
    padding: 10px 20px;
  }
  &[data-step="reviewing"] {
    .question-builder__tab {
      padding: 40px;
    }
  }
  .question-builder__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 4;
    .error-msg {
      background-color: $coral-light;
      padding: 8px 12px;
      border-radius: $border-radius;
      color: $red;
      margin-right: 12px;
    }
    .question-builder__footer__info-link {
      font-size: $font-size-xs;
    }
    .button-wrap {
      min-width: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &.button-wrap__right-side {
        justify-content: flex-end;
      }
    }
    .choose-lo__next {
      border-width: 1px;
    }
    .choose-lo__next-survey {
      font-size: 14px;
      line-height: 19px;
      padding: 8px 20px;
    }
  }
}
.learnosity-author__wrap {
  height: 100%;
}
.learnosity-author-container {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  .loading-spinner {
    height: 100%;
  }
  .lrn-author-item-nav-right .lrn-author-save-button-group {
    visibility: hidden;
  }
  padding-bottom: 40px; // pad for bottom menu bar
}
.row .question-builder__select, .row .question-builder__input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: inherit;
  text-align: left;
  font-size: 14px;
  color: $gray-dark;
  padding: 10px;
  label {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
.question-builder__input {
  input {
    height: 38px;
    border: 1px solid $gray;
    border-radius: 4px;
  }
}
.question-builder__select {
  overflow-y: visible;
  z-index: $zindexModalTooltip;
  padding: 15px;
  margin-bottom: 60px;
}

.question-builder__error-msg {
  position: absolute;
  right: 10px;
  top: -40px;
  box-shadow: $box-shadow;
  background-color: $white;
  color: $red;
  padding: 6px 12px;
  border-radius: $border-radius;
  font-size: small;
  text-align: center;
}
