@import "~sass-includes/index";

.codon-text-logo {
  text-align: center;
  color: $purple;
  font-size: 28px;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 16px;
  span:first-child {
    font-weight: bold;
  }
  span:last-child {
    color: $true-black;
  }
}
