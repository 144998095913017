@import "~sass-includes/index";

.piechart-legend {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 10px 0;
  .piechart-legend__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: center;
    align-items: center;
    margin: 0 6px;
    .piechart-legend__color {
      width: 40px;
      height: 12px;
      margin-right: 8px;
    }
  }
}

.bar-group .name-label {
  text-anchor: end;
  font-weight: 300;
  font-size: 1em;
  fill: $black;
}

.bar-group .value-label {
  text-anchor: end;
  fill: $white;
  font-weight: 900;
  font-size: 0.7em;
  &.is-zero {
    display: none;
  }
}

.container .bar-group {
  transition: opacity 0.3s;
}

/* Fade bars when the chart is hovered */
.container:hover .bar-group {
  opacity: 0.3;
}

/* But don't fade the hovered bar... */
.container .bar-group:hover {
  opacity: 1;
}

.container .bar-group:hover .name-label {
  font-weight: 400;
}

.container .bar-group:hover .value-label {
  font-size: 0.8em;
}

.lo-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .lo-chart__title {
    text-align: center;
    margin-bottom: 16px;
  }
  svg {
    cursor: default;
  }
}
.lo-chart__lo-info {
  padding: 8px;
  min-height: 100px;
  .lo-chart__lo-info-description {
    margin-top: 8px;
    font-size: $font-size-sm;
  }
}
