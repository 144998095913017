@import "~sass-includes/index";

.question-list {
  width: calc(100% - 420px);
  margin-right: 20px;
  border: 1px solid $gray;
  color: $gray-dark;
  max-height: 100%;
  overflow: auto;
}
.question-list__table {
  width: 100%;
  display: table;
  border-collapse: collapse;

  thead tr th {
    position: sticky;
    top: 0;
    background-color: $gray;
    &.question-list__item-los {
      padding-left: 32px;
    }
  }

  tfoot {
    position: sticky;
    bottom: 0;
    background-color: $gray-light;
    .question-list__footer-cell {
      display: flex;
      width: 100%;
      margin: 0px;
      padding: 0px;
      justify-content: space-between;
      align-items: center;
      font-size: $font-size-sm;
      font-weight: normal;
      color: $black;
      height: 10px;
      > button {
        margin: 0px;
        padding: 0px;
        color: $gray-dark;
        &:disabled {
          color: $gray-light;
          cursor: default;
        }
      }
      > svg {
        height: 30px;
      }
    }
  }

  thead.question-list__header {
    color: $gray-dark;
    padding-bottom: 15px;
    margin-bottom: 5px;
    .question-list__question-count {
      font-weight: normal;
      color: $black;
    }
  }
  th {
    text-align: left;
    padding: 15px 8px;
    font-weight: 800;
    font-size: $font-size-sm;
  }
  tbody.question-list__wrapper {
    tr:last-child {
      border-bottom: 0;
    }
    .question-list__item-row {
      min-height: 40px;
      border-bottom: 1px solid;
      border-color: $gray;
      font-size: $font-size-sm;
      .question-list__assigned-pill {
        text-align: center;
        width: 110px !important;
        margin-top: 10px;
        font-size: $font-size-xxs;
        background-color: $blue-lighter;
        border-radius: 10px;
        padding: 2px 5px;
        cursor: default;
      }
    }
  }
  td {
    vertical-align: middle;
    padding: 8px;
    &.question-list__item-title {
      width: calc(65%);
      text-align: left;
      .question-list__item-title-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        .question-list__edit-custom-question {
          margin-right: 4px;
          margin-top: 3px;
          svg {
            fill: $purple;
          }
          &.custom-item-icon__coinstructor-indicator {
            svg {
              fill: $gray-dark;
            }
          }
        }
      }

    }
    &.question-list__item-lo-list {
      max-width: 140px;
      cursor: default;
      display: flex;
      flex-direction: row;
      align-items: center;
      .question-list__lo-items {
        flex-wrap: wrap;
        margin-left: 8px;
      }
      .question-list__lo-tooltip {
        margin: 4px 0;
      }
      .question-list__lo-string {
        white-space: nowrap;
      }
      button {
        svg {
          color: $purple;
        }
        margin-left: 4px;
      }
    }
    &.question-list__item-blooms {
      max-width: calc(8%);
      cursor: default;
    }
    &.question-list__info-action {
      max-width: calc(8%);
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: row;
      svg {
        width: 32px;
      }
    }
  }
}

// Grouped questions
.question-list__table {
  tbody.question-list__wrapper {
    .question-list-group__group-title {

    }
    .question-list__item-row {
      .question-list-group__group-title {
        button {
          svg {
            margin-right: 8px;
          }
        }
      }
      &[data-groupexpanded="true"] {
        .question-list-group__group-title {
          button {
            svg {
              transform: rotate(90deg);
            }
          }
        }
      }
      &[data-isgrouped="true"] {
        td {
          padding: 4px 8px;
          .question-list__badge {
            display: none;
          }
          .item-blooms__value {
            visibility: hidden;
          }
          .question-list__group-order {
            background-color: $gray-light;
            color: $black;
            padding: 4px 8px;
            border-radius: 12px;
            margin-right: 8px;
          }
        }
        td.question-list__item-title {
          padding-left: 18px;
        }
      }
    }
  }
}

.question-list__custom-quit {
  @extend %button;
  margin-top: 10px;
  color: $purple;
  border: 1px solid $purple;
  background: transparent;
  width: 153px;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    color: $white;
  }
  margin-right: 10px;
}

.question-list__assessment-preview {
  @extend %standardLink;
  font-weight: 400;
  margin-right: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }
}

.question-list__assessment-star {
  position: absolute;
  left: -20px;
}
