@import "~sass-includes/index";

.topic-popup__select {
  width: 1;
  color: $gray-dark;
}
.topic-popup__wrapper {
  position: absolute;
  z-index: $zindexPopup;
  max-height: 300px;
  width: 400px;
  opacity: 1;
  border: 1px solid $purple;
  border-radius: 7px;
  padding: 4px;
  background-color: $white;
  display: flex;
  flex-direction: column;
}
.topic-popup__selected-items-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.topic-popup__selected-items {
  opacity: 1;
  background-color: $white;
  padding: 1px 4px 4px;
  padding-top: 1px;
  border-bottom: solid 2px $purple;
  margin: 1px;
  line-height: 19px;
}
.topic-popup__selected-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $gray-light;
  border: 1px solid $gray;
  padding: 1px 14px;
  border-radius: 25px;
  box-shadow: 1px 2px 2px $gray;
  cursor: pointer;
  position: relative;
}
.topic-popup__remove-item-hover {
  display: flex;
  align-items: center;
  vertical-align: center;
  position: absolute;
  right: 0;
  visibility: hidden;
}
.topic-popup__selected-item:hover {
  background-color: $coral-light;
  border: 1px solid $gray;
  .topic-popup__remove-item-hover {
    visibility: visible;
  }
}
.topic-popup__subject {
  background-color: $gray-light;
  padding: 5px;
  margin-top: 2px;
  font-weight: bold;
  padding: 5px;
  padding-left: 2px;
  cursor: pointer;
  &:hover {
    background-color: $gray;
  }
  svg {
    margin-right: 2px;
  }
}
.topic-popup__additional-subjects-divider {
  height: 10px;
  margin-top: 7px;
  border-top: 1px solid $gray-dark;
  border-bottom: 1px solid $gray-dark;
}
.topic-popup__additional-subjects-header {
  padding: 5px;
  margin-top: 2px;
  padding: 5px;
  padding-left: 2px;
}
.topic-popup-item-parent {
  background-color: $gray-light;
  padding: 5px;
  padding-left: 15px;
  margin-top: 2px;
  cursor: pointer;
  &:hover {
    background-color: $gray;
  }
  svg {
    margin-right: 2px;
  }
}
.topic-popup__add-custom {
  margin: 3px;
  margin-left: 10px;
  padding: 2px 4px 2px 5px;
  border: 2px solid $purple-light;
  background-color: $purple-light;
  border-radius: 5px;
  cursor: pointer;
}
.topic-popup__add-custom:hover {
  background-color: $purple;
  border-color: $purple;
  color: $white;
}
.topic-popup__item-list-container {
  position: relative;
  overflow-y: scroll;
}
.topic-popup__item-list-selected {
  padding: 4px 4px 4px 5px;
  margin: 3px;
  margin-left: 30px;
  color: $gray-dark;
  cursor: default;
  &:hover {
    background-color: $gray-light;
    border-radius: 5px;
    svg {
      fill: $white;
    }
  }
}
.topic-popup__item-list-unselected {
  padding: 4px 4px 4px 5px;
  margin: 3px;
  margin-left: 27px;
  cursor: pointer;
  &:hover {
    background-color: $purple;
    border-radius: 5px;
    color: $white;
    svg {
      fill: $white;
    }
  }
}
.topic-popup__item-list.topic-popup__unit-list {
  justify-content: space-between;
}
.topic-popup__item-list.topic-popup__topic-list {
  margin-left: 12px;
  .topic-popup__topic-list-trigger {
    padding-right: 8px;
  }
}

.topic-popup__topic-list-trigger {
  position: relative;
}
.topic-popup__sub-popup {
  position: absolute;
  bottom: 0;
  z-index: $zindexPopup;
  max-width: 350px;
  // overflow:hidden;
  width: 350px;
  opacity: 1;
  border: 1px solid $purple;
  border-radius: 7px;
  padding: 4px;
  background-color: $gray;
  display: flex;
  flex-direction: column;
  padding: 23px;
  ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 30px;
    font-size: $font-size-sm;
    li {
      padding: 4px 0;
    }
  }
}
.topic-popup__sub-popup.sub-popup__unit {
  left: 360px;
}
.topic-popup__sub-popup.sub-popup__topic {
  left: 400px;
}
.topic-popup__item-topic-list {
  position: absolute;
  right: -20px;
  top: 0;
}

.topic-popup__close {
  font-size: $font-size-sm;
}
.topic-popup__close:hover {
  color: $red;
}
