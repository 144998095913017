@import "~sass-includes/index";
$topic-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$highlight-box-shadow: 0px 1px 8px 2px $blue;
$base-padding: 16px;
$left-padding: 30px;
$menu-trigger-height: 28px;
$compact-min-row-height: 50px;
$compact-base-padding: 12px;
$compact-border: 1px solid $gray;
/* <CoursePlannerClassRow Top Level> */
.bcp__class-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  transition: all 150ms linear;
  .bcp__class-row__gutter {
    width: 32px;
    position: relative;
    .context-menu__trigger {
      border-radius: 4px 0 0 4px;
      svg {
        fill: $white;
      }
      transition: all 800ms $ease-out-quint;
      background-color: $blue;
      opacity: 0.8;
      &:hover {
        background-color: $blue;
        width: 32px;
        opacity: 1;
      }
      &[data-available="false"] {
        background-color: $blue-lighter;
      }
    }
    .bcp__class-row__gutter__menu-wrap {
      position: absolute;
      right: 0px;
      top: $base-padding;
      height: $menu-trigger-height;
      .bcp__class-row__cog-button {
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: $menu-trigger-height;
        height: $menu-trigger-height;
        border-radius: 4px 0 0 4px;
        svg {
          width: 24px;
          height: 24px;
          fill: $white;
          margin-top: 4px;
          margin-left: 6px;
        }
        transition: all 800ms $ease-out-quint;
        background-color: $blue;
        opacity: 0.8;
        &:hover {
          background-color: $blue;
          width: 32px;
          opacity: 1;
        }
        &[data-available="false"] {
          background-color: $blue-lighter;
        }
      }
      .bcp__class-row__gutter-menu {
        position: absolute;
        bottom: $menu-trigger-height;
        left: 0px;
        width: 200px;
        background-color: $white;
        box-shadow: $menu-box-shadow;
        z-index: 2;
        ul, li {
          @extend %list-reset;
        }
        button {
          padding: 4px 12px 4px 8px;
          text-align: left;
          font-size: small;

        }
      }
    }
  }
  .bcp__class-row__content {
    width: 100%;
    position: relative;
    transition: all 150ms linear;
    &[data-overlaid="true"] {
      min-height: 270px;
      .bcp__class-row__card {
        min-height: 270px;
      }
    }
  }
  .bcp__class-row__actions {
    &[data-classtype="special"] {
      visibility: hidden;
    }
    min-width: 140px;
    .bcp__class-row__actions__buttons {
      padding: 0 $compact-base-padding;
      padding-top: 4px;
    }
    font-size: $font-size-sm;
    .bcp__action-button {
      margin-bottom: 8px;
      .bcp__action-button__label {
        margin-left: 4px;
        text-align: left;
      }
    }
  }
  .bcp__header-button {
    background-color: $blue;
    color: $white;
    padding: 6px 12px;
    border-radius: $border-radius;
    &:hover {
      background-color: $blue-dark;
    }
    font-weight: bold;
  }
  .bcp__class-topic {
    .bcp__topic-kebab {
      .kebab-menu__dropdown.kebab-dropdown-bottom {
        right: 24px;
        top: 0;
      }
      .kebab-menu__dropdown__item {
        font-size: $font-size-sm;
      }
    }
  }
}
/* </CoursePlannerClassRow Top Level> */

/* <CoursePlannerClassRow Body> */
.bcp__class-row__body {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: $base-padding 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  .bcp__class-row__info-column {
    padding: 0 $base-padding;
    min-width: 120px;
    .bcp__class-row__info-column__class-date-number {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }
    .bcp__class-row__info-column__class-date-display {
      font-weight: 400;
      font-size: 14px;
      color: $black;
    }
  }
  .bcp__class-row__day-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px $base-padding 12px 22px;
    border: 1px solid $gray;
    &[data-editing="true"] {
      border: 1px solid $blue;
      box-shadow: 0px 0px 7px 1px $blue;
    }
    border-radius: $border-radius;
    box-shadow: $topic-box-shadow;
    margin-bottom: 8px;
    &[data-classtype="special"] {
      margin-bottom: 0;
    }
    .bcp__class-row__day-label__icon {
      padding: 8px 16px 8px 12px;
      svg {
        color: $purple;
      }
    }
    .bcp__class-row__day-label__contents {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .bcp__class-row__day-label__label {
        display: flex;
        flex-direction: column;
        font-size: 22px;
        line-height: 150%;
        .bcp__class-row__day-label__edit {
          padding: 4px;
          width: 100%;
          line-height: inherit;
        }
        .bcp__class-row__day-label__display {
          margin-left: 10px;
        }
        button.edit-class-label {
          @extend %hover-icon-button;
          margin-left: 8px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      .special-class-checkbox {
        display: flex;
        align-items: center;
        input {
          margin-left: 6px;
          width: 18px;
          height: 18px;
        }
        &[data-disabled="true"] {
          color: $gray-dark;
        }
      }
      .bcp__class-row__day-label__contents__details {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        font-size: small;
        line-height: 24px;
        padding-left: 8px;
        .no-class-label {
          padding-left: 4px;
        }
        .special-class-checkbox__wrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          .special-class-checkbox__explain-special-forbidden {
            margin-left: 5px;
            @extend %flex-centered;
            svg {
              width: 14px;
              height: 14px;
              color: $coral;
            }
          }
        }
      }
      .bcp__class-row__day-label__actions {
        padding-left: 8px;
        font-size: 14px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        .toggle-switch {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          .toggle-switch-labels > span {
            font-size: small;
            padding-right: 4px;
          }
        }
        .bcp__class-row__day-label__actions__buttons {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .main-action-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          }
          .aux-action-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 8px;
            button {
              @extend %hover-icon-button;
            }
          }
        }
      }
    }
  }

  .bcp__class-row__card {
    border-radius: 10px;
    box-shadow: $topic-box-shadow;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &[data-highlight="true"] {
      box-shadow: $highlight-box-shadow;
    }
    .bcp__class-row__card__header {
      position: relative;
      // default header bg color
      background-color: $blue-lighter;
      color: $black;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: $compact-min-row-height;
      align-items: center;
      border-radius: calc(#{$border-radius} - 1px) calc(#{$border-radius} - 1px) 0 0;
      .bcp__class-row__card__header-title {
        padding: 14px 12px 14px 14px;
        font-weight: 600;
        font-size: 16px;
        width: 100%;
        text-align: left;
        small {
          margin-left: 6px;
          font-size: $font-size-xs;
        }
        &[data-clickable="false"] {
          cursor: default;
        }
      }
      .bcp__class-row__card__header-action {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .topic-action-button {
          margin-right: 8px;
        }
        .bcp__kebab-menu {
          button.kebab-menu__trigger {
            position: relative;
            top: 0;
            svg {
              fill: $gray-dark;
            }
            figure {
              width: 4px;
              height: 4px;
            }
            padding: 0 12px;
          }
          .kebab-menu__dropdown {
            font-size: $font-size-sm;
          }
          padding-left: 8px;
        }
      }
    }
    .bcp__class-row__card__body {
      .bcp__class-row__card__body__empty {
        font-size: $font-size-sm;
        padding: $base-padding $left-padding;
      }
    }
    &[data-row-card-type="study-path"] {
      .bcp__class-row__card__header {
        background-color: $purple-dark;
        color: $white;
      }
      .bcp__class-row__card__body {
        padding: $base-padding $base-padding $base-padding $left-padding;
        font-size: $font-size-sm;
        .bcp__class-row__card__body__row {
          line-height: 28px;
        }
      }
    }
    &[data-isempty="true"], &[data-expanded="false"] {
      // round the header corners if topic item is empty
      .bcp__class-row__card__header {
        border-radius: $border-radius;
      }
      .bcp__class-row__card__body {
        padding: 0;
      }
    }
    &[data-row-card-type="user"] {
      .bcp__class-row__card__header {
        background-color: $purple;
        color: $white;
        border-radius: $border-radius $border-radius 0 0;
      }
    }
  }

  .bcp__class-row__move-topic {
    button {
      width: 100%;
      height: 100%;
      min-height: $compact-min-row-height;
    }
  }
  .bcp__class-row__move-lo {
    button {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 44;
      padding: 0 8px;
      svg {
        margin-right: 4px;
      }
    }
  }
}
/* </CoursePlannerClassRow Body> */

/* <CoursePlannerClassTopicLearningObjective> */
.bcp__class-row__card__lo {
  &:not(:last-child) {
    border-bottom: 1px solid $gray;
  }
  padding: $base-padding 31px;
  position: relative;
  .bcp__class-row__card__lo-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .kebab-menu__wrap {
      position: absolute;
      top: 0;
      right: 0;
      button.kebab-menu__trigger {
        figure {
          width: 4px;
          height: 4px;
        }
      }
    }
    .bcp__lo-expando-button {
      padding-right: $base-padding;
      svg {
        color: $blue;
      }
      &:disabled {
        svg {
          color: $gray;
        }
      }
    }
    .bcp__lo-alignment {
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .bcp__lo-details {
      display: inline;
      margin: 0 $base-padding 0 $left-padding;
      .bcp__lo-number {
        display: inline;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin-right: 4px;
      }
      .bcp__lo-title {
        display: inline;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin-right: 20px;
      }
    }
  }
  &[data-expanded="true"] {
    .bcp__lo-expando-button {
      svg {
        transform: rotate(90deg);
      }
    }
  }
  .bcp__lo-expando__body {}
}

svg.custom-lo-indicator__icon, .bcp__lo-actions__custom-lo-indicator > svg {
  fill: $gray-dark;
  width: 1em;
  height: 1em;
  top: .125em;
  position: relative;
  margin: 0 4px;
}
.bcp__lo-actions__custom-lo-indicator {
  display: inline-flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  svg {
    fill: $purple;
  }
  &:hover {
    svg {
      fill: $purple-dark;
    }
  }
  &.custom-item-icon__coinstructor-indicator {
    svg {
      fill: $gray-dark;
    }
  }
}
/* </CoursePlannerClassTopicLearningObjective> */

$lo-row-padding-x: 8px;
$lo-row-padding-y: 12px;

/* <COMPACT MODE> */
.bcp__class-row[data-bcp-view-mode-class-row="compact"] {
  margin-bottom: 0;
  .bcp__class-row__card {
    &[data-highlight="true"] {
      box-shadow: $highlight-box-shadow;
    }
    &:not(:last-child) {
      margin-bottom: 0;
    }
    border-radius: 0;
    box-shadow: none;

    .bcp__class-row__card__body__empty {
      padding: $compact-base-padding;
    }
    .bcp__class-row__card__lo {
      padding: 0;
      .bcp__class-row__card__lo-row {
        padding-left: $lo-row-padding-x;
        border-right: 40px solid transparent;
      }
      .bcp__lo-alignment, .bcp__lo-details {
        padding: $lo-row-padding-y 0;
      }
      .bcp__lo-details {}
      &[data-custom-lo="true"] {
        // min height to give room to custom LO indicator
        // min-height: 66px;
      }
      &[data-highlight="true"] {
        background-color: $gold-light;
      }
    }
    .bcp__expando-caret {
      padding: $compact-base-padding;
    }
    .bcp__cancel-x {}
    &.bcp__class-topic {
      .bcp__class-row__card__header .bcp__kebab-menu {
        figure {
          background: $gray-dark;
        }
        .cross svg {
          fill: $white;
        }
      }
    }
    &:not(:last-child) {
      .bcp__class-row__card__header {
        border-bottom: $compact-border;
      }
    }
  }
  .bcp__class-row__body {
    padding: 0;
    box-shadow: none;
    min-height: $compact-min-row-height;
    border-bottom: $compact-border;
    border-left: $compact-border;
    border-right: $compact-border;
    .bcp__class-row__day-label {
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 0;
      border: 0;
      border-left: $compact-border;
      border-right: $compact-border;
      .bcp__class-row__day-label__icon {
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .bcp__class-row__day-label__contents {
        .bcp__class-row__day-label__label {
          font-size: $font-size-md;
          font-weight: 600;
          .bcp__class-row__day-label__display {
          }
        }
      }
    }
    .bcp__class-row__day-label .bcp__class-row__day-label__icon {
      padding: 0;
      padding-top: 8px;
    }
    .bcp__class-row__day-label {
      &[data-editing="true"] {
        padding: 8px 4px 8px 0;
        .bcp__class-row__day-label__icon {
          svg {
            width: 32px;
            height: 32px;
          }
          padding-left: $compact-base-padding;
          padding-right: $compact-base-padding;
        }
      }

    }
    .bcp__class-row__card__header {
      border-radius: 0;
    }
    .bcp__class-row__card__body {
      border-left: $compact-border;
      border-right: $compact-border;
    }
  }
  .bcp__class-row__day-label {
    padding: 4px $compact-base-padding 6px;
  }
  .bcp__class-row__actions.bcp__class-row__actions__show-more-wrap {
    margin-top: 2px;
    .class-action-button__show-more-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      .bcp__action-button__label {}
    }
  }
  .bcp__class-row__card.bcp__lo-builder__edit {
    .bcp__class-row__card__body {
      border-left: 0;
      border-right: 0;
      border-bottom: $compact-border;
    }
  }
}
/* </COMPACT MODE> */

/* <CoursePlannerClassRowOverlay> */
.bcp__class-row__overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  .overlay__add-study-path {
    padding: 12px;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    .overlay__add-study-path__header {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      padding-bottom: 8px;
    }
    .overlay__add-study-path__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      width: calc(100% - 100px);
    }
    .overlay__add-study-path__body {
      display: flex;
      flex-direction: column;
      width: 100%;
      .overlay__add-study-path__body__input {
        font-size: 20px;
        display: flex;
        flex-direction: column;
        width: calc(50% - 30px);
        padding-top: 12px;
        width: 100%;
        label {
          margin-bottom: 6px;
        }
        input {
          width: 100%;
          &#study-path-title {
            padding: 8px;
          }
        }
      }
    }
    .overlay__add-study-path__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: $base-padding;
    }
  }
}
// </CoursePlannerClassRowOverlay>
// <CoursePlannerClassRowStudyPath>
.bcp__class-row__study-path {
  .bcp__class-row__study-path__assessment-table {
    width: 100%;
    th, td {
      text-align: left;
      vertical-align: middle;
    }
    th, .small-text {
      font-size: $font-size-xs;
    }
  }
}
.bcp__class-row__study-path__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  .bcp__class-row__study-path__body__input {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    width: calc(50% - 30px);
    width: 100%;
    label {
      margin-bottom: 6px;
    }
    input {
      width: 100%;
      &#study-path-title {
        padding: 8px;
      }
    }
  }
}
.bcp__class-row__study-path__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: $base-padding;
}
// </CoursePlannerClassRowStudyPath>
