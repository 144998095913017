@import "~sass-includes/index";

$header-height: 56px;
$header-padding: 10px;

//TODO should this be a shared style? because the wrapper may be repeated outside of Assessment Builder?
.assessment-question-selector__wrapper {
  border-top: 0;
  position: relative;
  z-index: 1;
  p span {
    color: $purple;
  }
}

.assessment-question-selector__header-wrap {
  display: flex;
  justify-content: flex-start;
  position: relative;
  border-radius: 10px;
}

.assessment-question-selector__header-filter-drawer {
  z-index: 20;
}

.assessment-question-selector__expand-filter {
  z-index: 50;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-size: $font-size-sm;
  cursor: pointer;
  border-radius: 5px 0px 0px;
  &[data-expanded="true"] {
    border-radius: 5px 0px 0px;
    border-left: 0px;
  }
  color: $white;
  background-color: $purple;
  border-right: 1px solid $white;
  min-width: 275px;
  svg {
    margin-right: 5px;
  }
  &:hover {
    color: $white;
  }
}

.assessment-question-selector__header-filtering-by {
  flex-grow: 7;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 24px 10px 39px;
  color: $white;
  background: $purple;
  background-color: $gray-dark;
  border-left: 1px solid $white;
  border-radius: 0px 5px 0px 0px;
}

.assessment-question-selector__list {
  display: flex;
  justify-content: space-between;
  max-height: calc(100vh - #{$header-height} - #{$header-padding} - 60px);
  width: 100%;
  margin-top: $header-padding;
}

.assessment-question-selector__lo-filter {
  margin-right: 5px;
  font-size: $font-size-xs;
  color: $purple;
  background-color: $white;
  border: 1px solid $purple;
  border-radius: $border-radius-lg;
  padding: 0px 10px;
  white-space: nowrap;
}

.assessment-question-selector__header-filtering-by-criteria {
  display: inline-block;
  padding-top: 5px;
  .filtering-by__lo-list {
    margin-left: 12px;
  }
}

.assessment-question-selector__header-filtering-by-search {
  display: inline-block;
  svg {
    margin: 0 5px;
  }
  input {
    width: 200px;
    border-color: $gray;
    border-radius: 10px;
    padding: 5px;
  }
}

.assessment-question-selector__summative-message {
  display: inline-block;
  margin: 10px;
  font-size: $font-size-xl;
  color: $coral;
}
