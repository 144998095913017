@import "~sass-includes/index";

button.button {
  border: none;
  background: none;
  cursor: pointer;
  &:focus {
    outline: 1px solid $gray;
  }
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
  &::-moz-focus-inner {
    border: 0;
  }
  svg {
    outline: none;
    transition: transform 0.15s linear;
  }
  &:disabled {
    cursor: default;
  }
  border-width: 0;
  border-style: none;
}

button.button.primary {
  @include primary-button;

  &.new-button {
    background-color: $blue;
    border-radius: $border-radius;
    font-weight: 600;
    font-size: $font-size-sm;
    line-height: 19px;
    &:disabled {
      background-color: $blue-light;
      color: $white;
    }
  }
}

button.button.secondary {
  @include secondary-button;
  &.new-button {
    border: 1px solid $blue;
    color: $blue;
    border-radius: $border-radius;
    font-weight: 600;
    font-size: $font-size-sm;
    line-height: 19px;
  }
}

button.button.selected {
  @include primary-button-selected;
}

button.button.primary, button.button.secondary {
  .icon-wrap {
    margin-right: 12px;
    &.no-text {
      margin-right: 0;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &.has-icon {
    padding-left: 20px;
  }
  &.right-icon {
    flex-direction: row-reverse;
    .icon-wrap {
      margin-left: 12px;
      margin-right: 0;
    }
  }
}

button.button.gray-button {
  background: $gray-dark;
  border-radius: 20px;
  min-width: 148px;
  padding: 8px 16px;
  color: $white;
}

button.button.big-button {
  padding: 16px 41px;
  font-size: $font-size-sm;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: center;
}

button.text-button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  &:hover, &:focus-visible {
    text-decoration: underline;
    outline: none;
  }
}
