@import "~sass-includes/index";

.dev-utils__container {
  opacity: 1;
  border: 1px solid $purple;
  border-radius: 7px;
  padding: 20px;
  margin: 10px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dev-utils__header {
  color: $purple-dark;
  padding: 10px;
  background-color: $white;
  display: flex;
  flex-direction: column;
}
