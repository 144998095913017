@import "~sass-includes/index";

$search-height: 32px;
$search-color: $purple-light;
$search-color-active: $blue-dark;

.search-input {
  width: 100%;
  position: relative;
  display: flex;
  font-size: small;
  height: $search-height;
  .search-input__input-element {
    width: 100%;
    border: 3px solid $search-color;
    border-right: none;
    padding: 5px 8px;
    border-radius: $border-radius-lg 0 0 $border-radius-lg;
    line-height: $search-height;
    outline: none;
    color: $gray-dark;
  }
  .search-input__button {
    @extend %flex-centered;
    width: 36px;
    height: $search-height;
    border: 1px solid $search-color;
    background: $search-color;
    text-align: center;
    color: $white;
    border-radius: 0 $border-radius-lg $border-radius-lg 0;
    cursor: pointer;
    font-size: $font-size-md;
    svg {
      margin-right: 4px;
    }
  }
  &:focus-within, &[data-search-active="true"] {
    .search-input__input-element {
      border: 3px solid $search-color-active;
      color: $black;
    }
    .search-input__button {
      border: 1px solid $search-color-active;
      background: $search-color-active;
    }
  }
}
