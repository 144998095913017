@import "~sass-includes/index";

.modal-overlay {
  @extend %modal__overlay;
  background-color: $modal-overlay-background;
  .modal-wrapper {
    @extend %modal__wrapper;
    position: fixed;
    top: 0;
    left: 0;
    outline: 0;
  }
  .modal {
    z-index: #{$zindexModal + 20};
    background: $white;
    border-radius: 10px;
    box-shadow: $box-shadow;
    border: 1px solid $gray;
    position: relative;
    @media #{$breakpoint-sm-up} {
      position: fixed;
      top: 0px;
      left: 40px;
      right: 40px;
    }
    @media #{$breakpoint-md-up} {
      top: 0px;
      left: 80px;
      right: 80px;
    }
    @media #{$breakpoint-lg-up} {
      top: 0px;
      left: 80px;
      right: 80px;
    }
    overflow: hidden;
    bottom: 0px;
    .modal-content {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      @media #{$breakpoint-sm-up} {
        // padding-bottom: 100px;
      }
      .learnosity-item__wrap {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }
  }

  .modal-header {
    position: absolute;
    top: 20px;
    right: 20px;
    @media #{$breakpoint-sm-up} {
      right: 15px;
    }
  }

  .modal-close-button {
    position: sticky;
    z-index: #{$zindexModal + 30};
    // make x button easier to click on mobile
    svg {
      width: 28px;
      height: 28px;
    }
    @media #{$breakpoint-sm-up} {
      svg {
        width: inherit;
        height: inherit;
      }
    }
  }
}

.modal-small {
  background: $white;
  position: relative;
  max-height: 60%;
  border-radius: 3px;
  min-width: 500px;
  max-height: 570px;
  border-radius: 10px;
  box-shadow: $box-shadow;
  border: 1px solid $gray;
}

.modal-content-wrapper {
  position: relative;
  max-height: 100%;
}

.dopamine-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .dopamine-modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 64px 96px;
    height: 100%;
    font-size: $font-size-md;
  }
  .dopamine-modal__title {
    font-weight: 600;
    font-size: $font-size-xl;
    line-height: 27px;
    text-align: center;
    margin-bottom: 32px;
  }
  .dopamine-modal__description {
    height: 100%;
  }
  .opamine-modal__hero-button {
    margin-top: auto;
  }
}
