@import "~sass-includes/index";

$btn-radius: $border-radius-sm;

.button-radio-toggle {
  ul {
    @extend %list-reset;
    @extend %flex-row-centered;
    li.button-radio-toggle__item {
      @extend %list-reset;
      .button-radio-toggle__label {
        padding: 6px 16px;
        background-color: $gray-light;
      }
      &[data-checked="true"] {
        .button-radio-toggle__label {
          background-color: $blue-lighter;
        }
      }
      input[type="radio"] {
        position: absolute;
        margin: 0 !important;
        padding: 0 !important;
        opacity: 0;
        height: 0;
        width: 0;
        pointer-events: none;
      }
      &:first-child {
        label span {
          border-radius: $btn-radius 0 0 $btn-radius;
        }
      }
      &:last-child {
        label span {
          border-radius: 0 $btn-radius $btn-radius 0;
        }
      }
    }
  }
}
