// styles shared across components within this controller should be centralized here
@import "~sass-includes/index";
.assessments-controller {
  @include centered($max-width);
  .assessments-controller__tab-wrap {
    min-height: 800px;
    overflow: hidden;
    .accommodations-tab-wrap {
      border-radius: $border-radius;
    }
  }
}
.spb-tooltip-content {
  .tooltip-text {
    padding-right: 8px;
    font-size: $font-size-sm;
  }
  img {
    max-width: 100%;
  }
}

.question-list__badge {
  color: $gray-dark;
  font-size: 12px;
  font-weight: bold;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: $border-radius;
  padding: 2px 5px;
  margin-left: 5px;
}
