@import "~sass-includes/index";

.assessment-intro-outro {
  li {
    margin: 15px 0;
  }
}

.assessment-intro-outro__content {
  @extend .codon-card;
  /* Card border */
  > .assessment-taker-outro__content {
    @media #{$breakpoint-sm-up} {
      padding: 50px 55px 30px;
      width: 80%;
    }
  }
  .assessment-taker-outro__content-side-by-side {
    @media #{$breakpoint-sm-up} {
      padding: 50px 55px 30px;
      width: 100%;
    }
  }
}

.assessment-intro-outro__infotip {
  background-color: $gray;
  display: flex;
  flex-direction: row;
  padding: 24px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.assessment-intro-outro__infotip-text {
  font-size: $font-size-sm;
  line-height: 140%;
}
.assessment-intro-outro__infotip-icon {
  margin-right: 20px;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.assessment-taker-outro__box {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  width: 100%;
  .assessment-taker-outro__box-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .assessment-taker-outro__box-right {

  }
}

.assessment-taker-outro__side-by-side {
  display: flex;
  flex-direction: row;
  .assessment-taker-outro__side-by-side-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    .assessment-taker-outro__side-by-side-box-left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    .assessment-taker-outro__side-by-side-box-right {
    }
  }

}

.assessment-taker-outro__content {
  padding: 12px 8px;
  @media #{$breakpoint-sm-up} {
    > div {
      flex: 1;
    }
  }
  .assessment-taker-outro__content-title {
    font-family: $standard-font;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    margin-bottom: 1em;
  }
  .assessment-taker-outro__content-text {
    font-family: $standard-font;
    font-size: $font-size-sm;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
  }
  width: 100%;
}

.assessment__action {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  @media #{$breakpoint-xs-only} {
    button.button.big-button {
      padding: 16px;
    }
  }
  @media #{$breakpoint-sm-up} {
    padding: 30px 55px;
  }
  width: 100%;
  div {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: center;
    text-align: center;
    .text-button {
      margin-left: 8px;
    }
    i {
      font-size: $font-size-sm;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .assessment-outro__review {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
    text-align: left;
  }
  .assessment-outro__review-assessment-instructor {
    background-color: $red-light;
    color: $white;
    font-weight: 600;
    width: 100%;
    margin-top: 8px;
    padding: 12px 16px;
    border-radius: 8px;
    text-align: center;
  }
}

.assessment-outro__review-assessment {
  @extend %standardLink;
  font-weight: 700;
}

.assessment-taker-outro__practice-test-metacog {
  table {
    width: 90%;
    border-collapse: separate;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  thead th {
    text-align: left;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
  }
  th:first-child {
    border: transparent;
    background-color: transparent;
  }
  td {
    padding: 10px;
    border-radius: 5px;
  }
  tbody td:first-child {
    font-weight: bold;
    text-align: left;
    border: 0px;
    padding-left: 0px;
    width: 75px;
  }
  .practice-test-metacog-cell {
    &.metacog__clear-correct, &.metacog__muddy-incorrect {
      background-color: $blue-lighter;
    }
    &.metacog__clear-incorrect, &.metacog__muddy-correct {
      background-color: $gray-light;
    }
    .practice-test-metacog-cell__content {
      @extend %flex-centered;
      display: flex;
      flex-direction: column;
      align-items: center;
      .practice-test-metacog-cell__icon {
        width: 44px;
        height: 44px;
      }
    }
  }
}
