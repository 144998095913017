@import "~sass-includes/index";

.multi-course-qa-dashboard {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  margin: 0px 30px;

  .multi-course-qa-dashboard-search {
    display: flex;
    flex-direction: row;
    margin: 5px;
    align-items: center;
    font-weight: bold;

    .multi-course-qa-dashboard-search__form {
      @extend %flex-row-centered;
      margin: 5px;
      background-color: $gray-light;
      border: 1px solid;
      border-radius: 5px;
      padding: 5px;
      width: 100%;

      textarea {
        font-family: $standard-font;
        width: 600px;
        border: 1px solid;
        border-color: $gray;
        border-radius: 7px;
        margin: 0px 5px;
        padding: 10px 5px;
        line-height: normal;
      }
    }
  }

  .multi-course-qa-dashboard__course {
    margin: 30px 5px 5px;
    .multi-course-qa-dashboard__course-data {
      margin-bottom: 5px;
    }
  }
}
