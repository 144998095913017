@import "~sass-includes/index";

@keyframes __react-svg-spinner_infinite-spin {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}
.react-svg-spinner__circle {
  transition-property: transform;
  animation-name: __react-svg-spinner_infinite-spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.loading-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 360px;
  &.timed-out {
    $warn-color: $purple-dark;
    color: $warn-color;
    svg {
      fill: $warn-color;
    }

  }
  .loading-spinner__loading-text {
    margin-top: 16px;
    text-align: center;
  }
}
