@import "~sass-includes/index";
.assessment-details {
  form, .assessment-builder__form {
    border: 1px solid $gray;
    border-radius: $border-radius;
  }
  input[type="checkbox"] {
    margin-top: 0;
  }
  .is-disabled {
    color: $gray;
  }
}
.assessment-builder__assess-type-grouping {
  margin-bottom: 12px;
  padding: 5px 5px 5px 10px;
  border-radius: 7px;
  background-color: $gray-light;
}

.assessment-builder__assess-type-grouping-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  .usage-message {
    color: $coral;
    font-weight: bold;
    border-radius: 3px;
    background-color: $white;
    padding: 2px;
  }
}

.assessment-builder__quit, .assessment-builder__back {
  @extend %button;
  margin-top: 10px;
  color: $purple;
  border: 1px solid $purple;
  background: transparent;
  &:hover {
    color: $white;
  }
}
.assessment-builder__select {
  margin-top: 8px;
}
.assessment-builder__delete {
  @extend %button;
  margin-top: 10px;
  margin-right: auto;
  color: $purple;
  border: 1px solid $purple;
  background: transparent;
}
.assessment-builder__delete-active {
  @extend .assessment-builder__delete;
  &:hover {
    color: $white;
  }
}
.assessment-builder__delete-disabled {
  @extend .assessment-builder__delete;
  cursor: default;
  &:hover {
    color: $purple;
    background: transparent;
  }
}

.assessment-builder__message {
  color: $coral !important;
  border-radius: 3px;
  background-color: $gray-light;
  padding: 4px;
  font-size: 13px;
  margin: 12px 0;
  font-style: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 4px;
  }
}
.assessment-builder__message-tooltip {
  color: $coral;
  font-weight: bold;
}
.has-been-started__tooltip {
  min-width: 350px;
}
.assessment-builder__message-tooltip-header {
  font-weight: bold;
}

.assessment-builder__due-date {
  display: flex;
  flex-direction: row;
}

.form-wrapper {
  display: flex;
  justify-content: space-evenly;
  &.summative {
    flex-direction: column;
  }
  textarea {
    resize: none;
  }
}
.form__field {
  padding-right: 0px;
  &.row {
    margin-left: 0px;
  }
  &.toggle-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
    min-height: 20px;
    &.grade-sync-toggle-row {
      margin-bottom: 10px;
    }
    &.late-policy-toggle-row {
      margin-bottom: 10px;
      .late-policy-tooltip {
        margin-right: 15px;
      }
    }
    .toggle-switch {
      margin-left: 15px;
      margin-right: 15px;
    }
    .toggle-label {
      margin-right: 10px;
      &[data-is-prep-or-practice=true] {
        padding-left: 25px;
      }
    }
    .is-disabled {
      color: $gray;
    }
  }
}

.late-policy-row {
  margin-bottom: 24px;
  .form__field {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0px;
    .react-datepicker-wrapper {
      width: 50%;
    }
    input[type="number"] {
      height: 30px;
      border: 1px solid $gray;
      border-radius: 4px;
      padding: 0 5px;
    }
  }
}

.form-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gray;
  h3 {
    height: 50px;
  }
}
.form-col-left, .form-col-right {
  padding: 24px 40px 0px;
  width: 50%;
}
.form-col-left {
  border-right: 1px solid $gray;
  &.no-border {
    border: none;
  }
}
.form-col-right {
}
.form-control {
  background-color: $gray;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  p {
    margin: auto 20px;
  }
}

.form-informational {
  font-size: smaller;
}

.lo-pill-list-wrap {
  margin-top: 12px;
  margin-left: 12px;
}

.class-session-dropdown {
  .class-days-covered__menu {
    z-index: 2; // make sure dropdown shows above ToggleSwitch
  }
}

.assessment-builder__assessment-name {
  height: 44px !important;
}

.assessment-builder__published {
  height: 170px;
  background-color: $gray-light;
  padding: 10px;
  margin-bottom: 20px;
  .published-info {
    font-size: smaller;
  }
}
