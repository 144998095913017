@import "~sass-includes/index";

.grade-sync {
  @include centered($max-width);
  @extend %diptych-layout;
  display: flex;
  flex-direction: column;
  .grade-sync__menubar {
    @extend %diptych-layout__header;
    margin-bottom: 10px;
  }
  .grade-sync__activity-notice {
    border: 2px solid $gray-dark;
    border-radius: $border-radius;
    background-color: $blue-lighter;
    margin: 10px 0;
    padding: 5px;
  }
  .grade-sync__activity-container {
    table {
      border-collapse: collapse;
      font-family: $standard-font;
      border: 1px solid;
      border-color: $gray;
      box-shadow: 2px 2px $gray-light;
      width: 100%;
      margin-bottom: 60px;
      td:first-child, th:first-child {
        padding-left: 35px;

      }
      td:last-child, th:last-child {
        padding-right: 25px;
      }
    }
    thead {
      text-align: left;
      width: 90%;
    }
    th {
      font-size: $font-size-sm;
      font-weight: bold;
      border-bottom: 1px solid $gray-dark;
      padding: 15px 5px 8px;
    }
    tr {
      height: 30px;
      &.first-row {
        height: 15px;
      }
      &.spacer-row {
        height: 20px;
        border-top: 1px solid;
        border-color: $gray-light;
      }
    }
    td {
      padding: 2px 4px;
      text-align: left;
      font-size: 13px;
      font-weight: normal;
    }
  }
  .grade-sync-list__empty {
    background-color: $gray-light;
    height: 100%;
    padding: 8px 12px;
  }

  .grade-sync__row {
    @extend %diptych-layout__row;
    .grade-sync__column {
      .grade-sync__section {
        @extend %diptych-layout__section;
      }
      .grade-sync__section-header {
        @extend %diptych-layout__section-header;
        &.eligible {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .missing-info-text {
          font-size: smaller;
          text-decoration: underline;
        }
      }
      &.grade-sync__column-right {
        @extend %diptych-layout__column-right;
      }
      .grade-sync__action-bar {
        @extend %diptych-layout__action-bar;
        .grade-sync__action-bar-item {
          font-style: italic;
          padding-right: 40px;
        }
        .grade-sync__action-bar-button {
          white-space: nowrap;
          max-height: 35px;
          margin: 2px;
        }
      }
    }
  }
  ul.grade-sync-list {
    @extend %diptych-layout__list;
  }
}

.grade-sync-list__name {
  @extend %diptych-layout__list-name;
}
.assess-type-pill {
  @extend %assess-type-pill;
}
