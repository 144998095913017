@import "~sass-includes/index";

.reorder-buttons {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  float: right;
  height: 100%;
  .reorder-buttons__left, .reorder-buttons__right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      &:first-child {
        border-radius: $border-radius-sm 0 0 0;
        border-bottom: 1px solid $blue-light;
      }
      &:last-child {
        border-radius: 0 0 0 $border-radius-sm;
      }
      display: table-cell;
      padding: 4px;
      &[data-action="top"] {
        background-color: $purple-light;
      }
      &[data-action="bottom"] {
        background-color: $purple-lighter;
      }
      color: $white;
      &[data-action="up"] {
        background-color: $blue;
      }
      &[data-action="down"] {
        background-color: $blue;
      }
      &:hover {
        background-color: $blue-dark;
      }
      &:disabled {
        background-color: $gray-light;
        color: $gray;
      }
    }
  }
}
