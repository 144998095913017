@import "~sass-includes/index";

.checkbox-item__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 8px;
  text-align: left;
  align-items: flex-start;
  .checkbox-item__icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 5px;
    height: 100%;
    svg {
      transition: all 300ms $ease-out-quint;
      width: 18px;
      height: 18px;
    }
  }
  .checkbox-item__label {
    text-align: left;
  }
  &:disabled {
    cursor: default;
    svg {
      opacity: 0.5;
    }
  }
}
