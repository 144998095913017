@import "~sass-includes/index";
.svg {
  max-width: 100%;
  transform: rotate(-90deg);
}

.svg-circle-bg {
  fill: none;
}

.svg-circle {
  fill: none;
}
.svg-circle-text {
  font-size: $font-size-xxs;
  text-anchor: middle;
  transform-origin: 50% 50%;
  transform: rotate(90deg);
  fill: $black;
}
