@import "~sass-includes/index";

$cubic-out: cubic-bezier(.32, 2.04, .85, .54);
$cubic-in: cubic-bezier(.72, 1.2, .71, .72);
$easing: cubic-bezier(1.000, 0.000, 0.000, 1.000);

$transition-time: 0.2s;
$trigger-size: 32px;

.context-menu__wrap {
  position: relative;
  button.context-menu__trigger {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: $trigger-size;
    height: $trigger-size;
    top: 0;
  }

  &.context-menu-small {
    button.context-menu {
      padding: 0 6px;
      position: relative;
      top: 0;
      figure {
        width: 4px;
        height: 4px;
        margin: 2px;
      }
    }
  }

  ul.context-menu__dropdown {
    @extend %list-reset;
    position: absolute;
    z-index: $zindexPopup;
    transition: all $transition-time $easing;
    transform: scale(0);
    transform-origin: 0% 0%;
    top: 4px;
    &.context-dropdown-right {
      left: $trigger-size;
    }
    &.context-dropdown-left {
      right: $trigger-size;
      transform-origin: 100% 100%;
    }
    box-shadow: $menu-box-shadow;
    background-color: $white;
    li {
      display: block;
      width: 100%;
      text-align: left;
      a, button {
        font-weight: 600;
        text-align: left;
        width: 100%;
        padding: 1em 18px;
        display: inline-block;
        white-space: pre;
        box-sizing: border-box;
        color: $black;
        &:hover {
          background: $gray;
        }
        > svg {
          margin-right: 8px;
        }
        &:disabled {
          color: $gray-dark;
          cursor: default;
        }
      }
    }
    &:hover {
      ul {
        transform: scale(1);
      }
    }
  }
  &[data-expanded="true"] {
    ul.context-menu__dropdown {
      transform: scale(1);
      transition: all $transition-time $easing;
    }
  }
}
