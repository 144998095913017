@import "~sass-includes/index";

.assessment-nav {
  display: flex;
  margin-top: 20px;
  .assessment-nav__left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    .assessment-nav__title {
      padding-right: 12px;
    }
  }
}
.exit-block__button {
  padding-left: 16px;
}
.assessment-nav__wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  .summative-nav-buttons {
    .nav-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      &:disabled {
        cursor: default;
      }
      .crumb-name {
        padding-left: 4px;
        color: inherit;
        line-height: 20px;
        min-width: 120px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: $font-size-md;
        div {
          color: inherit;
        }
        .crumb-name__subtitle {
          font-size: $font-size-xs;
        }
      }
      &.assessmentReviewNav {
        min-width: 140px;
      }
    }
  }
  div, button {
    display: flex;
    opacity: 1;
    line-height: 150%;
    color: $purple;
    text-align: left;
    align-items: center;
    user-select: none;
    &:disabled {
      cursor: default;
      color: $gray-dark;
    }
    &.is-active {
      opacity: 1;
      color: $purple;
    }
    &.is-inactive {
      opacity: 1;
      color: $gray-dark;
    }

    &.is-selected {
      opacity: 1;
      color: $purple;
      border-bottom: 3px solid;
    }
    svg {
      display: block;
      height: 50px;
      width: 50px;
      position: relative;
      top: 0px;
    }
  }
}
.exit-block-no-text {
  padding-left: 15px;
  svg {
    height: 100%;
    width: 20px;
    cursor: pointer;
  }
}
.assessment-builder-nav__exit-btn {
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }
}
