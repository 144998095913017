@import "~sass-includes/index";

.progress-bar {
  width: 100%;
  background: $gray-light;
  border: $border-color;
  box-sizing: border-box;
  border-radius: 40px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.progress-bar__indicator {
  border-radius: 20px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  z-index: 2;
}
.progress-bar__percentage {
  font-size: $font-size-sm;
  text-align: center;
  z-index: 3;
}
