@import "~sass-includes/index";
.lo-builder {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  .lo-builder__form {
    display: flex;
    flex-direction: column;
    .lo-builder__form-item {
      &:first-child {
        margin-top: 16px;
      }
      margin-bottom: 16px;
    }
    textarea, select, .bcp__topic-editor__content-form {
      margin-top: 8px;
      width: 100%;
    }
    input.bcp__topic-editor__content__input {
      border-radius: 4px;
    }
    textarea {
      height: 6em;
    }
    button.lo-builder__save {
      margin: 0;
      margin-bottom: 8px;
    }
    .lo-builder__dropdown-label {
      margin-bottom: 8px;
    }
    .lo-builder__select {
      margin-top: 8px;
      width: 100%;
    }
    .lo-builder__create-topic-wrap {
      position: relative;
      border-right: 24px solid transparent;
      button.lo-builder__cancel-create-topic {
        position: absolute;
        right: -24px;
        bottom: 0;
        width: 28px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
  h2 {
    margin-bottom: 32px;
  }
  .lo-builder__copy-lo-warning {
    background-color: $gold-light;
    margin-bottom: 8px;
    padding: 12px 16px;
    border-radius: 8px;
  }
  .lo-builder__unit-group-header {
    color: $black;
    font-size: larger;
    font-weight: bold;
  }
  .lo-builder__topic-warning {
    color: $coral;
    font-size: 13px;
  }
}

.bcp__class-row__card__body {
  .lo-builder {
    margin-bottom: 0;
    padding: 0 20px;
    padding-bottom: 12px;
    h2 {
      display: none;
    }
  }
}
.lo-selector-modal__lo-builder {
  .lo-builder {
    @include centered(600px);
    max-width: 80%;
  }
}
