@import "~sass-includes/index";
/**
 * SPTC Frontside
 */

$left-padding: 20px;
$flipper-width: 12px;

.study-path-topic-card {
  box-sizing: border-box;
  overflow: hidden;
  margin: 12px 0;
  &:first-child {
    margin-top: 0px;
  }
  &.open {
    .header-card-status > .header-card-status-icons {
      svg {
        fill: $white;
      }
    }
  }
  button.study-path-topic-card__header {
    box-sizing: border-box;
    padding: 20px 16px 24px 20px;
    font-family: $standard-font;
    font-style: normal;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border-radius: 10px 10px 0 0;
    .header-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .class-number {
        font-size: $font-size-xs;
        line-height: 140%;
      }
      .topic-name {
        font-weight: 600;
        font-size: $font-size-xl;
        line-height: 22px;
      }
    }
    .header-card-status {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      text-align: right;
    }
    .header-expand-button {
      width: 26px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      font-size: $font-size-xs;
      line-height: 16px;
      text-align: right;
      text-decoration-line: underline;
    }
    .header-card-status-icons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      > .topic-card-status-icon {
        padding-left: 16px;
      }
    }
  }

  .study-path-topic-card__front {
    display: flex;
    flex-direction: column;
    .learning-objective-expando {
      display: flex;
      flex-direction: row;
      font-size: $font-size-sm;
      line-height: 140%;
      &:not(:last-child) {
        box-shadow: inset 0px -1px 0px $gray-dark;
      }
      color: $black;
      .topic-card-learning-objective__indicator {
        min-width: 64px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        svg {
          margin-top: 24px;
        }
      }
      .topic-card-learning-objective__description {
        display: flex;
        flex-direction: column;
        width: 100%;
        .topic-card-learning-objective__description-title {
          padding: 12px 8px 12px 0;
        }
        .topic-card-learning-objective__description-lo-number {
          font-size: $font-size-xs;
          line-height: 140%;
          letter-spacing: 0.03em;
          text-transform: uppercase;
          color: $gray-dark;
          padding-bottom: 12px;
        }
      }
      .topic-card-learning-objective__content-wrap {
        width: 100%;
      }
      .topic-card-learning-objective__header-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .lo-questions-list {
        .todo-questions, .past-questions, .no-questions {
          &:not(:last-child) {
            border-top: 1px solid $gray;
          }
          margin-left: 64px;
        }
        .no-questions {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          padding-top: 12px;
          padding-bottom: 16px;
          margin-left: 64px;
        }
        .lo-question-row {
          @extend %button-reset;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          width: 100%;
          padding: 12px 12px 12px 0px;
          .lo-question-row__indicator {
            width: 32px;
            display: flex;
            align-items: center;
          }
          .lo-question-row__status {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 60px;
            .status-icon {
              padding-left: 15px;
            }
          }
          .lo-question-row__content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            color: $black;
            .better-tooltip__trigger {
              cursor: pointer;
            }
          }
        }
      }
    }
    .study-path-topic-card__gutter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $black;
      .readiness-link {
        padding: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        fill: $black;
        font-weight: 600;
        svg {
          margin-left: 4px;
          fill: $black;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .study-path-topic-card__front, .study-path-topic-card__back {
    .study-path-topic-card__expanded-content {
      position: relative;
      border-right: $flipper-width solid transparent;
    }
    .topic-card-flipside {
      position: relative;
      border-left: $flipper-width solid transparent;
    }
    .study-path-topic-card__flipper {
      transition: all 0.3s ease;
      position: absolute;
      top: 0;
      bottom: 0;
      width: $flipper-width;
      svg {
        margin-bottom: 18px;
      }
      &:hover {
        width: 16px;
        svg {
          transition: fill 0.3s ease;
          fill: $white;
        }
      }
    }
  }
  .study-path-topic-card__front {
    .study-path-topic-card__flipper {
      right: -$flipper-width;
    }
  }
  .study-path-topic-card__back {
    .study-path-topic-card__flipper {
      border-radius: 8px 0 0 8px;
      left: -$flipper-width;
    }
  }
  &[data-hasreadiness="false"] {
    .study-path-topic-card__front, .study-path-topic-card__back {
      .study-path-topic-card__expanded-content {
        border-right: 0;
      }
      .topic-card-flipside {
        border-left: 0;
      }
      .study-path-topic-card__flipper {
        display: none;
      }
    }
  }
}

/**
 * SkipForNow
 */

.skip-for-now {
  font-weight: 600;
  font-size: $font-size-sm;
  line-height: 140%;
  color: $gray-dark;
  margin: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 8px;
  }
  > span {
    padding: 0 8px;
  }
  .better-tooltip__container.skip-for-now__tooltip {
    width: 70%;
  }
  .skip-for-now__cancel {
    color: $red-light;
    &:disabled {
      color: $coral-dark;
    }
  }
  .skip-for-now__confirm {
    color: $green;
    &:disabled {
      color: $green-dark;
    }
  }
}

// Computed styles for SkipForNow transition
.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

/**
 * SPTC Flipside
 */
.topic-card-flipside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .flipside-content {
    .flipside-content__resources-title {
      padding: 12px $left-padding 0;
      font-weight: 600;
      font-size: $font-size-xl;
      line-height: 22px;
      border-bottom: 2px solid $gray;
      padding-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .flipside-content__resources-title__icon {
        padding-right: 12px;
      }
    }
    .sptc__readiness-item {
      display: flex;
      flex-direction: column;
    }
    .sptc__readiness-item__header {
      @extend %flex-row-centered;
      padding: 12px $left-padding 4px;
      line-height: 22px;
      .sptc__readiness-item__header__all-correct {
        margin-right: 12px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      .sptc__readiness-item__lo-list {
        > span {
          white-space: nowrap;
          margin-right: 4px;
          font-size: small;
          color: $gray-dark;
          font-weight: bold;
        }
      }
      .sptc__readiness-item__title {
        font-weight: 600;
        padding-bottom: 8px;
      }
    }
    .sptc__readiness-item__items-list {
      @extend %list-reset;
      padding: 10px 0;
      margin: 0 20px 0 58px;
      border-top: 2px solid $gray;
      .items-list__item > button {
        @extend %flex-row-centered;
        justify-content: space-between;
        width: 100%;
        text-align: left;
        &:hover {
          background-color: $gray-light;
        }
        // saving this if we want to do border later
        // &:not(:first-child) { border-top: 1px solid $gray; }
        padding: 6px 0;
        .items-list__item__title {
          display: inherit;
          flex-direction: inherit;
          justify-content: flex-start;
          align-items: inherit;
          font-size: small;
          .items-list__item__correct-indicator {
            margin-right: 8px;
          }
        }
        .items-list__item__clarity {
          @extend %flex-centered;
          height: 100%;
        }
      }
    }
  }
  .flipside-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 20px;
    > button.flip-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      > svg {
        fill: $black;
      }
    }
  }
}

.flip-wrap {
  position: relative;
}
