@import "~sass-includes/index";

.instructions-controller {
  @include centered($max-width);
  .instruction-nav {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
