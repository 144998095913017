@import "~sass-includes/index";

$bar-padding: 8px;

.modal-content {
  .learnosity-item__wrap {
    .at-action-bar {
      position: relative;
      width: 100%;
      min-width: 100%;
      margin: 0;
      margin-top: 12px;
      @media #{$breakpoint-xs-only} {
        // gutter for mobile to make sure user can scroll all the way down
        margin-bottom: 200px;
      }
      @media #{$breakpoint-sm-up} {
        position: fixed;
        left: 40px;
        right: 40px;
        bottom: 8px;
        width: 90%;
        min-width: inherit;
        margin: 0 auto;
      }
      @media #{$breakpoint-md-up} {
        position: fixed;
        left: 90px;
        right: 90px;
        width: 80%;
      }
    }
  }
}
.row.at-action-bar {
  position: relative;
  margin-top: 12px;
  @media #{$breakpoint-sm-up} {
    max-width: calc(100% - 40px);
  }
  @media #{$breakpoint-md-up} {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  z-index: $zindexActionBar;
  background: $purple-lighter;
  border: 1px solid $border-color;
  box-sizing: border-box;

  box-shadow: $box-shadow;
  border-radius: 8px;

  // <ActionBarMuddyClear>
  .at-action-bar__muddy-clear {
    display: flex;
    flex-direction: row;

    padding: $bar-padding $bar-padding $bar-padding 16px;
    @media #{$breakpoint-sm-up} {
      padding: $bar-padding $bar-padding $bar-padding 22px;
    }
    @media #{$breakpoint-md-up} {
      padding: $bar-padding $bar-padding $bar-padding 44px;
    }
    justify-content: space-between;
    align-items: center;
    .at-action-bar__muddy-clear-explain {
    }

    .at-action-bar__muddy-clear-buttons {
      position: relative;
      text-align: center;
      @media #{$breakpoint-md-up} {
        margin-left: 16px;
      }
      &[data-show-hint="true"] {
        background-color: $blue-light;
        border-radius: 12px;
        padding-bottom: 0.8em;
      }
      .clarity-hint-subtitle {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: $black;
        padding-bottom: 2px;
      }
    }
    .at-action-bar__muddy-clear-explain {
      font-family: $standard-font;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-xs;
      @media #{$breakpoint-md-up} {
        font-size: $font-size-sm;
      }
      line-height: 140%;
      color: $black;
      small {
        display: block;
        font-size: $font-size-xs;
        color: $gray-dark;
      }
      &.survey-explain {
        padding-top: 14px;
        @media #{$breakpoint-md-up} {
          padding-top: 20px;
        }
      }
    }
  }
  // </ActionBarMuddyClear>

  .at-action-bar__post-validated {
    display: flex;
    flex-direction: row;
    .at-action-bar__post-validated-icon {
      padding-right: 8px;
    }
    .at-action-bar__post-validated-text {
      font-weight: 600;
      font-size: $font-size-sm;
      line-height: 140%;
      color: $black;
      small {
        color: $gray-dark;
      }
    }
    svg {
      fill: $black;
    }
  }
  .at-action-bar__check-answer__wrap {
    position: relative;
    .disabled-click-catcher {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  .at-action-bar__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 8px;
    flex-direction: row-reverse;
    @media #{$breakpoint-sm-up} {
      flex-direction: row;
      border-left: 1px solid $gray-dark;
    }
    @media #{$breakpoint-md-up} {
      padding: 12px 24px;
    }
    align-items: center;
    justify-content: space-between;

    .at-action-bar__validate {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
      }
      .at-action-bar__finish {
        @media #{$breakpoint-md-up} {
          margin-left: 24px;
        }
      }
    }
    .at-action-bar__can-recap {
      svg {
        fill: $blue;
      }
    }
    .at-action-bar__nav-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $gray-dark;
      button {
        padding: 8px;
        @media #{$breakpoint-sm-up} {
          padding: 12px;
        }
        &:disabled {
          color: $gray;
        }
        svg {
        }
      }
    }
    .at-action-bar__nav-text {
      white-space: nowrap;
      @media #{$breakpoint-xs-only} {
        display: none;
      }
    }
  }
}
// only apply min-width when within the VAT because there's no text between the arrows in the SPAT
.action-bar-AT {
  .at-action-bar__nav-text {
    min-width: 44px;
  }
}
