@import "~sass-includes/index";

.course-header-nav {
  background-color: $gray-dark;
  color: $white;
  .course-header-nav__title {
    h1 {
      font-weight: 400;
      color: $white;
      font-size: $font-size-xl;
      line-height: 30px;
    }
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: 600;
    font-size: $font-size-h4;
    line-height: 30px;
    align-items: center;
    @media #{$breakpoint-sm-up} {
      min-height: 86px;
    }
    a.course-header-nav__edit-button {
      display: flex;
      align-items: baseline;
      svg {
        margin-left: 0.5rem;
        color: $white;
        font-size: 1.425rem;
      }
      &.active {
        svg {
          fill: $blue;
        }
      }
    }
  }
  .course-header-nav__title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media #{$breakpoint-sm-up} {
      @include centered($max-width);
      padding: 0;
    }
    .course-header-nav__mobile-menu-wrap {
      position: relative;
      button.course-header-nav__mobile-menu-button {
        padding: 24px 18px;
        background-color: $gray-dark;
        &:focus, &:hover {
          background-color: $gray;
        }
      }
      .course-header-nav__mobile-menu {
        box-shadow: inset 0px -1px 0px $gray-dark;
        position: absolute;
        top: 100%;
        right: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        background-color: $gray-dark;
        z-index: #{$zindexStandardTooltip + 10};
        a {
          width: 100%;
          text-align: center;
          color: $white;
          padding: 12px 16px;
          border-top: 1px solid $gray;
          &:not(:last-child) {
          }
          &:hover, &:active {
            background-color: $blue-dark;
          }
        }
      }
    }
  }
  .course-header-nav__tab-bar-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media #{$breakpoint-sm-up} {
      @include centered($max-width);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .course-header-nav__tab-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media #{$breakpoint-sm-up} {
        flex-direction: row;
        align-items: flex-end;
        min-height: 44px;
      }
      .course-header-nav__tab-bar__item {
        .highlight-nav-tab {
          @extend %flex-row-centered;
          .highlight-nav-tab__bubble {
            margin-left: 4px;
            background-color: $blue;
            padding: 0 6px;
            font-size: $font-size-xs;
            border-radius: $border-radius;
          }
        }
        transition: all 300ms ease;
        vertical-align: baseline;
        font-weight: 400;
        @media #{$breakpoint-sm-up} {
          padding: 4px 32px;
          font-size: $font-size-md;
          line-height: 22px;
        }
        &.special-item {
          padding: 0 16px;
          .special-item__label {
            color: $white;
            font-weight: 400;
            padding-right: 12px;
            line-height: 30px;
          }
          .special-item__sub-item {
            color: $white;
            padding: 4px 8px;
            padding-bottom: 6px;
            font-weight: normal;
            font-size: $font-size-sm;
            line-height: 22px;
            margin-left: 4px;
            &.active {
              font-weight: 600;
              background-color: $gray-dark;
              border: 4px solid $blue;
              border-bottom: 2px solid $gray-dark;
              border-radius: $border-radius $border-radius 0 0;
            }
            &.sub-item__hero {
              small {
                background-color: $blue;
                padding: 0 6px;
                border-radius: $border-radius-sm;
              }
            }
          }
        }
        white-space: nowrap;
        display: block;
        text-decoration: none;
        color: $white;
        position: relative;
        border-bottom: 4px solid transparent;
        svg {
          margin-right: 8px;
        }
        &:hover {
          color: $white;
        }
        &.active {
          color: $white;
          font-weight: 400;
          @media #{$breakpoint-sm-up} {
            font-size: $font-size-xl;
            line-height: 27px;
          }
          border-bottom: 4px solid $blue;
        }
      }
    }
  }
}
