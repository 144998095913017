@import "~sass-includes/index";

.apply-presets-to-existing__container {
  display: flex;
  flex-direction: column;
  margin: 0px 20px 20px;
  max-height: calc(100% - 200px);
  .apply-presets-to-existing__message {
    margin-bottom: 20px;
  }
  .apply-presets-to-existing__spacer {
    padding-top: 10px;
  }
  .apply-presets-to-existing__status-row {
    .apply-presets-to-existing__status-row-item {
      padding: 5px;
      margin-bottom: 15px;
      text-align: center;
      font-size: $font-size-h4;
    }
  }
  .apply-presets-to-existing__status-display {
    padding: 5px;
    text-align: center;
    font-size: 56px;
  }
  .apply-presets-to-existing__progress-box {
    min-height: 100px;
    p {
      font-size: 32px;
      color: $green;
      text-align: center;
    }
    svg {
      width: 32px;
      height: 32px;
      fill: $green;
    }
  }
}
