@import "~sass-includes/index";

.calendar {
  width: 100%;
  .calendar__header {
    padding-bottom: 12px;
    padding-left: 8px;
    font-weight: 600;
    font-size: $font-size-xl;
    line-height: 27px;
    display: flex;
    justify-content: space-between;
    .calendar__header-nav-btns {
      button {
        &:last-child {
          margin-left: 20px;
        }
        svg {
          fill: $gray-dark;
        }
      }
    }
  }
  .calendar__body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .calendar__weekday-header {
      @extend %noselect;
      display: flex;
      flex-wrap: inherit;
      width: 100%;
      button {
        font-size: $font-size-xs;
        cursor: default;
        height: 24px;
      }
    }
    button {
      cursor: pointer;
      &:disabled {
        color: $gray-dark;
        cursor: default;
      }
    }
    .calendar-day__wrap {
      @extend %flex-centered;
      padding: 2px;
      width: 14.2%;
      height: 32px;
      cursor: pointer;
      .calendar-day {
        @extend %flex-centered;
        height: 24px;
        width: 24px;
        font-size: $font-size-sm;
        transition: all 600ms $ease-out-quint;
        width: 100%;
        border-radius: 4px;
        border: 1px solid transparent;
        &[data-today="true"] {
          @extend %today-indicate;
          font-weight: bold;
          color: $black;
        }
        &[data-isclassday="true"] {
          background-color: $blue-lighter;
        }
        &[data-selected="true"] {
          background-color: $blue;
          border-radius: 8px;
          color: $white;
        }
      }
    }
  }
}
