@import "~sass-includes/index";

.class-session-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $black;
  > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    svg {
      fill: $black;
    }
    &:disabled {
      visibility: hidden;
    }
  }
  .class-number-display {
    user-select: none;
    padding: 0 12px;
    font-weight: 600;
    font-size: $font-size-xl;
    line-height: 32px;
    min-width: 104px;
    text-align: center;
    white-space: nowrap;
  }
}
