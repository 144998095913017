@import "~sass-includes/index";

.users-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  margin: 0px 30px;

  p {
    font-weight: bold;
    margin: 5px;
  }

  .users-container-search {
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    .users-container-search__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: bold;

      .users-container-search__form {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 5px;
        background-color: $gray-light;
        border: 1px solid;
        border-radius: 5px;
        padding: 5px;

        input[type="text"] {
          width: 250px;
          border: 1px solid;
          border-color: $gray;
          border-radius: 7px;
          margin: 0px 5px;
          padding: 10px 5px;
          line-height: normal;
        }

        #user-id {
          width: 350px;
        }
      }
    }
  }

  .users-container-table {
    margin-top: 15px;
    width: 100%;
    max-width: 100%;

    p {
      text-align: center;
      background-color: $coral;
      color: $white;
      padding: 5px;
      border-radius: 5px;
    }

    table {
      border-collapse: collapse;
      border: 1px solid;
      border-color: $gray-light;
      font-size: $font-size-sm;
      width: 100%;
    }
    thead {
      background-color: $gray;
      text-align: left;
      height: 61px;
    }
    tbody {
      tr {
        &:hover {
          background-color: $coral-light;
        }
      }
    }
    th {
      font-weight: bold;
      border: 1px solid;
      border-color: $gray;
      padding: 5px;
    }
    tr {
      &:nth-child(even) {
        background-color: $gray-light;
      }
    }
    td {
      padding: 5px;
      text-align: left;
      font-weight: normal;
      border: 1px solid;
      border-color: $gray-light;
      vertical-align: middle;
    }
  }
}
